import { FC } from "react";
import { Form, Input, DatePicker, Button } from "antd";
import { useBackcalculateAlarmMutation } from "../../store/backcalculateAlarms/api";
import { Dayjs } from "dayjs";
import { convertTimezone } from "../../helpers/dayjsHelpers";

interface RecalculateAlarmsProps {
  setAlarmBackcalculation: (newValue: number) => void;
  siteId: string;
  incidentActualLeakSource?: string;
  setShowRecalculateModal: (newValue: boolean) => void;
  timezone: string;
}

interface FormValues {
  leakLocation: string;
  startTime: Dayjs;
  endTime: Dayjs;
}

const RecalculateAlarms: FC<RecalculateAlarmsProps> = ({
  setAlarmBackcalculation,
  siteId,
  incidentActualLeakSource,
  setShowRecalculateModal,
  timezone,
}) => {
  const [form] = Form.useForm();
  const [backcalculateAlarm, response] = useBackcalculateAlarmMutation();
  const onFinish = async (values: FormValues): Promise<void> => {
    const startTimeString = values.startTime.format("YYYY-MM-DD HH:mm:00");
    const endTimeString = values.endTime.format("YYYY-MM-DD HH:mm:00");
    const startTime = convertTimezone(startTimeString, timezone, "UTC");
    const endTime = convertTimezone(endTimeString, timezone, "UTC");
    await backcalculateAlarm({
      ...values,
      startTime,
      endTime,
      siteId,
    })
      .unwrap()
      .then((res) => {
        if (res.result === 0) {
          window.alert("No Events Found or cell not seen");
        }
      });
  };
  form.setFieldValue("leakLocation", incidentActualLeakSource);
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <p>This might take a few seconds*</p>
          <Form
            disabled={response.isLoading}
            form={form}
            onFinish={onFinish}
            wrapperCol={{ span: 18 }}
            layout="vertical"
          >
            <Form.Item label="Leak Location" name="leakLocation">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Start Time" name="startTime">
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>

            <Form.Item label="End Time" name="endTime">
              <DatePicker showTime format="YYYY-MM-DD HH:mm" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={response.isLoading}
              >
                Recalculate
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ flex: 1, background: "#FAFAFA", padding: 15 }}>
          <h2>Results:</h2>
          {response.data !== undefined && response.data.result !== 0 ? (
            <div>
              <div>
                <b>Average Emission Rate(MCF/h):</b> {response.data.result}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          height: 35,
          paddingTop: 15,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          disabled={response.data === undefined || response.data.result === 0}
          type="primary"
          onClick={() => {
            if (response.data !== undefined) {
              setAlarmBackcalculation(response.data.result);
              setShowRecalculateModal(false);
            }
          }}
        >
          Save Results
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => setShowRecalculateModal(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default RecalculateAlarms;
