export interface NodeOption {
  id: string;
  name: string;
  active: boolean;
}

export interface UnitOption {
  value: string;
  label: string;
}

export const unitOptions: UnitOption[] = [
  {
    value: "MCF",
    label: "MCF",
  },
  {
    value: "kg",
    label: "kg",
  },
];

export const STATS_TYPES = {
  NodePPM: "2",
  VOC: "3",
  NodePPMandVOC: "4",
  CO2: "5",
  AvgEmissionRates: "6",
};

export interface SiteStatsSelectionProps {
  selectedStat: string;
  statTypeOptions: Array<{ label: string; value: string }>;
  handleStatChange: (stat: string) => void;
  onNodeChange: (newNodes: string[]) => void;
  siteNodeOptions: NodeOption[];
  selectedNodeIds: string[];
  unitOptions: UnitOption[];
  selectedUnitOption: string;
  onUnitOptionChange: (unit: string) => void;
  refreshData: () => void;
  disabled: boolean;
  showLocalTime: boolean;
  handleChangeShowLocalTime: (newValue: boolean) => void;
  handleResetChartKey: () => void;
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
}

export type TimeSeriesUnit = "hour" | "day";
