import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";

export interface GpaqsRollingAveragesObject {
  "7_days_average_emission_rates": string;
  "7_days_average_standard_deviation": string;
  "30_days_average_emission_rates": string;
  "30_days_average_standard_deviation": string;
  "90_days_average_emission_rates": string;
  "90_days_average_standard_deviation": string;
}

interface GpaqsRollingAvgsPayload {
  siteId: string;
  date?: string;
}

export const gpaqsRollingAverages = createApi({
  reducerPath: "gpaqsRollingAveragesApi",
  baseQuery,
  tagTypes: ["GpaqsRollingAverages"],
  endpoints: (builder) => ({
    getGpaqsRollingAverages: builder.query<
    GpaqsRollingAveragesObject,
    GpaqsRollingAvgsPayload
    >({
      query: (payload: GpaqsRollingAvgsPayload) => {
        const { siteId, date } = payload;
        const hasDate = date !== undefined;
        if (hasDate) {
          return `gpaqsRollingAverages/${siteId}?date=${date}`;
        } else {
          return `gpaqsRollingAverages/${siteId}`;
        }
      },
      providesTags: ["GpaqsRollingAverages"],
    }),
  }),
});

export const { useGetGpaqsRollingAveragesQuery } = gpaqsRollingAverages;
