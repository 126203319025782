import { FC, useState } from "react";
import { Table, Modal, Button, Spin, Tooltip } from "antd";
import { useGetNodeQuery } from "../../store/nodes/api";
import { useGetNodeConfigQuery, useUpdateNodeConfigMutation } from "../../store/nodeConfig/api";
import { useGetLastNodePPMReadQuery } from "../../store/nodesPPM/api";
import { useGetLastNodeReportQuery } from "../../store/nodeReports/api";
import { useGetLastNodeLocationQuery } from "../../store/nodeLocationHistory/api";
import { nodeSignOffApi } from "../../store/nodeSignOff/api";
import { NodeSignOff } from "../../store/nodeSignOff/types";
import { deviceDataSnapshotApi } from "../../store/deviceDataSnapshot/api";
import { NodeInfoParams, TableData } from "./types";
import SignOff from "../SignOff/SignOff";
import UpdateNodeConfig from "./UpdateNodeConfig/UpdateNodeConfig";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {
  getLastReportTableData,
  getNodeConfigTableData,
  getNodeDetailsTableData,
} from "./tableData";

const NA = "N/A";
const GREEN = "#52C41A";
const greenBackgroundStyle = { background: GREEN, borderColor: GREEN };

const NodeInfo: FC<NodeInfoParams> = ({
  nodeId,
  siteName,
  userEmail,
  test = false,
}) => {
  const [showSignOff, setShowSignOff] = useState(false);
  const [showLogSignOff, setShowLogSignOff] = useState(false);
  const [showUpdateConfig, setShowUpdateConfig] = useState(false);
  const [showReboot, setShowReboot] = useState(false);
  const [updateNodeConfig] = useUpdateNodeConfigMutation();
  const [triggerSignOff, signOffResult] =
    nodeSignOffApi.endpoints.signOffNode.useLazyQuery();
  const [triggerLogSignOff] =
    nodeSignOffApi.endpoints.logSignOff.useLazyQuery();
  const [triggerRequestDeviceDataSnapshot] =
    deviceDataSnapshotApi.endpoints.requestDeviceDataSnapshot.useLazyQuery();

  const signOffData = signOffResult?.data?.result;
  let passedSignOff = false;
  if (signOffData !== undefined) {
    const testResultValues = Object.values(signOffData);
    const testResultBooleans = testResultValues.map(
      (testResult: { pass: boolean; description: string }) => testResult.pass
    );
    passedSignOff = !testResultBooleans.includes(false);
  }

  const signOffErrorMessage =
    siteName !== null
      ? `Node is currently assigned to ${siteName}.`
      : "Error signing off node, try again or contact info@earthview.io.";

  const nodeQueryResult = useGetNodeQuery({ id: nodeId });
  const [nodeObj] = nodeQueryResult?.data?.result ?? [null];

  const nodeConfigQueryResult = useGetNodeConfigQuery({ id: nodeId });
  const [nodeConfig] = nodeConfigQueryResult?.data?.result ?? [null];

  const lastNodePPMReadResult = useGetLastNodePPMReadQuery({ id: nodeId });
  const lastNodePPMRead = lastNodePPMReadResult?.data?.result ?? null;

  const lastNodeReportResult = useGetLastNodeReportQuery({ id: nodeId });
  const [lastNodeReport] = lastNodeReportResult?.data?.result ?? [null];

  const lastNodeLocationResult = useGetLastNodeLocationQuery({ id: nodeId });
  const [lastNodeLocation] = lastNodeLocationResult?.data?.result ?? [null];

  const snapshotTooltipTitle =
    lastNodePPMRead?.Timestamp !== null && lastNodePPMRead?.Timestamp !== ""
      ? null
      : "No data found, snapshot unavailable.";

  if (
    (nodeQueryResult?.isFetching ?? false) ||
    (nodeConfigQueryResult?.isFetching ?? false) ||
    (lastNodePPMReadResult?.isFetching ?? false) ||
    (lastNodeReportResult?.isFetching ?? false) ||
    (lastNodeLocationResult?.isFetching ?? false)
  ) {
    return (
      <div className={styles.SpinWrapper}>
        <Spin />
      </div>
    );
  }

  if (nodeObj === null) {
    return <div>Node not found.</div>;
  }

  const lastReadTimestampUTC = lastNodePPMRead?.Timestamp ?? NA;

  const nodeDetailsTableData =
    lastNodeLocation != null && lastNodePPMRead != null
      ? getNodeDetailsTableData(
        nodeObj,
        lastNodeLocation,
        lastNodePPMRead,
        test
      )
      : [];

  const nodeConfigTableData =
    nodeConfig != null ? getNodeConfigTableData(nodeConfig, test) : [];

  const lastReportTableData =
    lastNodeReport != null ? getLastReportTableData(lastNodeReport, test) : [];

  const RenderLabelsValues = (labelValueArr: TableData[]): JSX.Element => {
    const columns = [
      {
        dataIndex: "label",
        key: "label",
        className: styles.LabelColumn,
      },
      {
        dataIndex: "value",
        key: "value",
      },
    ];

    const getRowClassName = (_: unknown, index: number): string => {
      return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
    };

    return (
      <div className={styles.TableWrapper}>
        <Table
          dataSource={labelValueArr}
          columns={columns}
          showHeader={false}
          pagination={false}
          rowClassName={getRowClassName}
        />
      </div>
    );
  };

  const onClickRequestDeviceDataSnapshot = async (): Promise<void> => {
    const payload = {
      nodeId,
      endDate: lastReadTimestampUTC,
      recipients: userEmail,
    };
    const response = await triggerRequestDeviceDataSnapshot(payload);
    if (response.isSuccess) {
      window.alert(
        `Request successful. An email will be sent to ${userEmail}.`
      );
    } else {
      window.alert(
        "There was an error making the request. Please try again or contact the Earthview dev team."
      );
    }
  };

  // Sign Off Modal
  const SignOffModalTitle = (): JSX.Element => {
    return (
      <div className={styles.ModalTitle}>
        <span>Node Sign Off</span>
      </div>
    );
  };

  const onClickOkSignOffModal = (): void => {
    if (passedSignOff) {
      setShowLogSignOff(true);
    } else {
      setShowSignOff(false);
    }
  };

  const onClickCancelSignOffModal = (): void => {
    setShowSignOff(false);
    setShowLogSignOff(false);
  };

  // Log Sign Off Modal
  const LogSignOffModalTitle = (): JSX.Element => {
    return (
      <div className={styles.ModalTitle}>
        <span>Confirm Node Sign Off</span>
      </div>
    );
  };

  const onClickOkLogSignOffModal = async (): Promise<void> => {
    const payload = { nodeId, email: userEmail };
    await triggerLogSignOff(payload);
    setShowLogSignOff(false);
    setShowSignOff(false);
    window.alert("Node sign off logged.");
  };

  const onClickCancelLogSignOffModal = (): void => {
    setShowLogSignOff(false);
    setShowSignOff(false);
  };

  const onClickSignOff = async (nodeId: string): Promise<void> => {
    await triggerSignOff({ nodeId });
    setShowSignOff(true);
  };

  // Update Node Configuration Modal
  const UpdateConfigModalTitle = (): JSX.Element => {
    return (
      <div className={styles.ModalTitle}>
        <span>Update Node Configuration</span>
      </div>
    );
  };

  const closeUpdateConfigModal = (): void => {
    setShowUpdateConfig(false);
  };

  const onClickUpdateConfiguration = (): void => {
    setShowUpdateConfig(true);
  };

  // Reboot Modal
  const RebootModalTitle = (): JSX.Element => {
    return (
      <div className={styles.ModalTitle}>
        <span>Reboot Node</span>
      </div>
    );
  };

  const onClickOkReboot = (): void => {
    if (nodeConfig != null) {
      updateNodeConfig({ ...nodeConfig, RebootTrigger: 1 })
        .unwrap()
        .then((res) => {
          if (res.Message === "SUCCESS") {
            window.alert("Node will reboot on next check-in");
            setShowReboot(false);
          } else {
            console.log("error:", res);
            window.alert("Error triggering node reboot");
          }
        })
        .catch((e) => {
          console.log("error:", e);
          window.alert("Error triggering node reboot");
        });
    }
  };

  const onClickCancelReboot = (): void => {
    setShowReboot(false);
  };

  const onClickReboot = (): void => {
    setShowReboot(true);
  };

  return (
    <div className={styles.ComponentWrapper}>
      <Modal
        centered
        open={showSignOff}
        title={SignOffModalTitle()}
        onCancel={onClickCancelSignOffModal}
        onOk={onClickOkSignOffModal}
        width={1000}
      >
        {signOffResult.isSuccess ? (
          <SignOff signOffData={signOffData as NodeSignOff} />
        ) : (
          <span>{signOffErrorMessage}</span>
        )}
      </Modal>
      <Modal
        centered
        open={showLogSignOff}
        title={LogSignOffModalTitle()}
        onCancel={onClickCancelLogSignOffModal}
        onOk={async () => await onClickOkLogSignOffModal()}
      >
        <div>Log node sign off as {userEmail}?</div>
      </Modal>
      <Modal
        centered
        open={showUpdateConfig}
        title={UpdateConfigModalTitle()}
        footer={null}
        width={1000}
        onCancel={closeUpdateConfigModal}
      >
        {nodeConfig != null ? (
          <UpdateNodeConfig
            nodeConfig={nodeConfig}
            nodeConfigTableData={nodeConfigTableData}
            closeUpdateConfigModal={closeUpdateConfigModal}
          />
        ) : (
          <span>Failed to get NodeConfig.</span>
        )}
      </Modal>
      <Modal
        centered
        open={showReboot}
        title={RebootModalTitle()}
        onCancel={onClickCancelReboot}
        onOk={onClickOkReboot}
      >
        Are you sure you want to reboot node {nodeId}?
      </Modal>

      <div className={styles.NodeInfo}>
        <div className={styles.HeaderWrapper}>
          <Button
            type="primary"
            style={greenBackgroundStyle}
            onClick={() => onClickReboot()}
          >
            Reboot
          </Button>
          <Button
            type="primary"
            style={greenBackgroundStyle}
            onClick={() => onClickUpdateConfiguration()}
          >
            Update Configuration
          </Button>
          <Tooltip title={snapshotTooltipTitle}>
            <Button
              type="primary"
              style={greenBackgroundStyle}
              onClick={async () => await onClickRequestDeviceDataSnapshot()}
            >
              Download Data Snapshot
            </Button>
          </Tooltip>
          <Button
            type="primary"
            style={greenBackgroundStyle}
            onClick={async () => await onClickSignOff(nodeId)}
          >
            Sign Off
          </Button>
        </div>
        <div className={styles.FlexBox}>
          <div className={classNames(styles.TableHeader, styles.Section)}>
            <b>Details</b>
            {RenderLabelsValues(nodeDetailsTableData)}
          </div>
          <div
            className={classNames(
              styles.TableHeader,
              styles.Section,
              styles.MarginHoriz10px
            )}
          >
            <span>
              <b>Configuration</b>
            </span>
            {RenderLabelsValues(nodeConfigTableData)}
          </div>
          <div className={classNames(styles.TableHeader, styles.Section)}>
            <b>Report</b>
            {RenderLabelsValues(lastReportTableData)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NodeInfo;
