import { FC, useState } from "react";
import { Table, Space, Modal, Button } from "antd";
import { Key } from "antd/lib/table/interface";
import { useGetUnassignedNodesQuery } from "../../../store/nodes/api";
import { Node } from "../../../store/nodes/types";
import NodeForm from "../NodeForm/NodeForm";
import { AvailableNodesProps } from "../types";
import styles from "../styles.module.scss";
import QRCode from "qrcode";
import { PrinterOutlined, QrcodeOutlined } from "@ant-design/icons";
import QrCodesComponent from "./QrCodePages/QrCodePages";
import { onPrintQRCodes } from "./QrCodePages/helpers";

const initialNodeState = {
  SiteId: "",
  NodeId: "",
  active: false,
  elevation: "",
  latitude: "",
  longitude: "",
  MacAddress: "",
  name: "",
  OrgId: "",
  sampleHeight: "",
};

const AvailableNodes: FC<AvailableNodesProps> = ({
  searchInput,
  showAddAction = true,
}) => {
  const [formKey, setFormKey] = useState(0);
  const [node, setNode] = useState(initialNodeState);
  const { data, isError, isLoading } = useGetUnassignedNodesQuery(null);
  const [showAssignModal, setShowAssignModal] = useState(false);

  let nodeData: Node[] = [];
  if (!isError && !isLoading && data !== undefined) {
    nodeData = data.response
      .map((ele) => {
        return { ...ele, key: ele.NodeId };
      })
      .filter((item) =>
        String(item.NodeId).toLowerCase().includes(searchInput)
      );
  }

  const columns = showAddAction
    ? [
        {
          title: "MAC Address",
          dataIndex: "NodeId",
          key: "NodeId",
        },
        {
          title: "Latitude",
          dataIndex: "latitude",
          key: "latitude",
        },
        {
          title: "Longitude",
          dataIndex: "longitude",
          key: "longitude",
        },
        {
          title: "Signed Off On",
          dataIndex: "signedOffOn",
          key: "signedOffOn",
          render: (timestamp: string) => <div>{`${timestamp} (UTC)`}</div>,
        },
        {
          title: "Signed Off By",
          dataIndex: "signedOffBy",
          key: "signedOffBy",
        },
        {
          title: "Action",
          render: (node: Node) => (
            <Space size="middle">
              <a onClick={() => onClickAssign(node)}>Add</a>
            </Space>
          ),
        },
      ]
    : [
        {
          title: "MAC Address",
          dataIndex: "NodeId",
          key: "NodeId",
        },
        {
          title: "Latitude",
          dataIndex: "latitude",
          key: "latitude",
        },
        {
          title: "Longitude",
          dataIndex: "longitude",
          key: "longitude",
        },
        {
          title: "Signed Off On",
          dataIndex: "signedOffOn",
          key: "signedOffOn",
          render: (timestamp: string) => <div>{`${timestamp} (UTC)`}</div>,
        },
        {
          title: "Signed Off By",
          dataIndex: "signedOffBy",
          key: "signedOffBy",
        },
      ];

  const onClickAssign = (node: Node): void => {
    setFormKey(formKey + 1);
    setNode(node);
    setShowAssignModal(true);
  };

  const onCancelAssign = (): void => {
    setShowAssignModal(false);
  };

  const getRowClassName = (_: unknown, index: number): string => {
    return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
  };

  const [showQRCodes, setShowQRCodes] = useState(false);
  const [qrCodes, setQRCodes] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const onGenerateQRCodes = async (): Promise<void> => {
    const codes = await Promise.all(
      selectedRowKeys.map(
        async (key: Key) => await QRCode.toDataURL(key as string)
      )
    );
    setQRCodes(codes);
    setShowQRCodes(true);
  };

  const onSelectChange = (selectedRowKeys: Key[]): void => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Modal
        centered
        open={showAssignModal}
        title="Assign Node"
        footer={null}
        onCancel={onCancelAssign}
      >
        <NodeForm
          key={formKey}
          node={node}
          isAssign={true}
          onSubmit={onCancelAssign}
          onCancel={onCancelAssign}
        />
      </Modal>
      <h3>Available Nodes</h3>
      <div className={styles.QrCodeControls}>
        <Button
          icon={<QrcodeOutlined />}
          onClick={onGenerateQRCodes}
          disabled={selectedRowKeys.length === 0}
        >
          Generate QR Codes
        </Button>
        <Modal
          width={850}
          className={styles.QrCodeModal}
          onCancel={() => setShowQRCodes(false)}
          centered
          open={showQRCodes}
          title="QR Codes"
          footer={[
            <Button
              icon={<PrinterOutlined />}
              key="print"
              type="primary"
              onClick={onPrintQRCodes}
            >
              Print
            </Button>,
          ]}
        >
          <QrCodesComponent
            qrCodes={qrCodes}
            selectedRowKeys={selectedRowKeys as string[]}
          />
        </Modal>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={nodeData}
        loading={isLoading}
        rowClassName={getRowClassName}
        pagination={{ defaultPageSize: 100 }}
        scroll={{ y: "50vh" }}
      />
    </div>
  );
};

export default AvailableNodes;
