import { FC } from "react";
import classNames from "classnames";
import { Button, Input, Select, Switch } from "antd";
import { useGetSiteAlarmsConfigQuery } from "../../../store/siteAlarmsConfig/api";
import { useAppSelector } from "../../../store/hooks";
import { currentSiteSelector } from "../../../store/sites/selector";
import { AlarmDetailsProps } from "./types";
import { useGetGlobalConfigQuery } from "../../../store/globalConfig/api";
import styles from "./styles.module.scss";
import { convertTimezone } from "../../../helpers/dayjsHelpers";
import { useGetGpaqsRollingAveragesQuery } from "../../../store/gpaqsRollingAverages/api";
import AlarmAlertTable, {
  leakLocationName,
} from "./AlarmAlertTable/AlarmAlertTable";
import ConcentrationAlarmTable from "./ConcentrationAlarmTable/ConcentrationAlarmTable";
import "./styles.scss";
import { displayTextMcfAndKg } from "./helpers";

const AlarmDetails: FC<AlarmDetailsProps> = ({
  alarmRollingAverageConfig,
  alertRollingAverageConfig,
  selectedIncidentAlarmType,
  shouldAlarm,
  ppm,
  nodeId,
  recipients,
  endTime,
  mostLikelyLeakEvent,
  mostLikelyLeakEventII,
  timestamp,
  initSetIncidentActualLeakSource,
  incidentActualLeakSource,
  incidentType,
  setIncidentType,
  incidentNotes,
  setIncidentNotes,
  onSaveAlarmIncident,
  timezone,
  selectIncidentActualLeakSource,
  siteNodes,
  siteGrid,
  setShowRecalculateModal,
  setShowConfidenceModal,
  recalculatedAverageEmissionsMCF,
  isAdmin,
}) => {
  const { TextArea } = Input;
  const currentSite = useAppSelector(currentSiteSelector);
  const siteId = currentSite?.SiteId ?? "";
  const { data } = useGetSiteAlarmsConfigQuery(siteId);
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const datePart = timestamp !== undefined ? timestamp.split(" ")[0] : "";
  const gpaqsRollingAveragesTimestamp = datePart + " 00:00:00";
  const averageGpaqsEmissionRates = useGetGpaqsRollingAveragesQuery({
    siteId,
    date: gpaqsRollingAveragesTimestamp,
  }).data;

  const alarmType =
    selectedIncidentAlarmType === "highConcentrationAlarm"
      ? "High PPM"
      : shouldAlarm ?? false
        ? "Alarm"
        : "Alert";

  let hits = 0;
  let stdvs = 0;
  let avg = 0.0;
  let rollingAveragesConfig = 1;

  let mcfToKg = 0;
  if (globalConfig?.mcfToKg != null) {
    mcfToKg = globalConfig.mcfToKg;
  }

  switch (alarmType) {
    case "Alarm":
      if (data !== undefined) {
        hits = data.alarmParams.hits;
        stdvs = data.alarmParams.sumOfDeviations;
        rollingAveragesConfig =
          alarmRollingAverageConfig === undefined
            ? data.alarmParams.rollingAverageConfig
            : alarmRollingAverageConfig;
        if (averageGpaqsEmissionRates !== undefined) {
          switch (data.alarmParams.rollingAverageConfig) {
            case 1:
              avg = parseFloat(
                averageGpaqsEmissionRates["7_days_average_emission_rates"]
              );
              break;
            case 2:
              avg = parseFloat(
                averageGpaqsEmissionRates["30_days_average_emission_rates"]
              );
              break;

            case 3:
              avg = parseFloat(
                averageGpaqsEmissionRates["90_days_average_emission_rates"]
              );
              break;
          }
        }
      }
      break;
    case "Alert":
      if (data !== undefined) {
        hits = data.alertParams.hits;
        stdvs = data.alertParams.sumOfDeviations;
        rollingAveragesConfig =
          alertRollingAverageConfig === undefined
            ? data.alertParams.rollingAverageConfig
            : alertRollingAverageConfig;
        if (averageGpaqsEmissionRates !== undefined) {
          switch (data.alertParams.rollingAverageConfig) {
            case 1:
              avg = parseFloat(
                averageGpaqsEmissionRates["7_days_average_emission_rates"]
              );
              break;
            case 2:
              avg = parseFloat(
                averageGpaqsEmissionRates["30_days_average_emission_rates"]
              );
              break;

            case 3:
              avg = parseFloat(
                averageGpaqsEmissionRates["90_days_average_emission_rates"]
              );
              break;
          }
        }
      }
      break;
  }

  let endTimeText = "";
  if (endTime === "new" || endTime === "ongoing") {
    endTimeText = endTime;
  } else if (endTime !== undefined) {
    endTimeText = `${convertTimezone(endTime, "UTC", timezone)} ${timezone}`;
  }

  const timestampToLocal = convertTimezone(timestamp, "UTC", timezone);

  return (
    <div className="alarm-details-container">
      {selectedIncidentAlarmType === "highConcentrationAlarm" ? (
        <ConcentrationAlarmTable
          ppm={ppm !== undefined ? ppm : 0}
          timestamp={timestampToLocal}
          siteNodes={siteNodes}
          node={nodeId !== undefined ? nodeId : ""}
          timezone={timezone}
        />
      ) : (
        <AlarmAlertTable
          alarmType={alarmType}
          hits={hits}
          stdvs={stdvs}
          avg={avg}
          mcfToKg={mcfToKg}
          recipients={recipients}
          mostLikelyLeakEvent={mostLikelyLeakEvent}
          mostLikelyLeakEventII={mostLikelyLeakEventII}
          timestamp={timestamp}
          timezone={timezone}
          averageGpaqsEmissionRates={averageGpaqsEmissionRates}
          endTimeText={endTimeText}
          rollingAveragesConfig={rollingAveragesConfig}
          siteGrid={siteGrid}
        />
      )}

      <h2>Alarm Triage</h2>
      <div>
        <div className={styles.selectActualLeakSourceWrapper}>
          <b>*Select Actual Leak Source</b>
          <div>
            <Switch
              size="small"
              onChange={(newValue: boolean) =>
                initSetIncidentActualLeakSource(newValue)
              }
              checked={selectIncidentActualLeakSource}
            />
          </div>
        </div>
        <div className={styles.marginTopBottom5} style={{ marginTop: 15 }}>
          <div className={styles.flexAlignedCenter}>
            <b>Actual Leak Source</b>
            <div
              className={classNames(styles.Label, styles.ActualLeakSource)}
            />
          </div>
          <Input
            disabled
            value={incidentActualLeakSource}
            style={{ marginTop: 5 }}
          />
        </div>
      </div>

      <div>
        {recalculatedAverageEmissionsMCF !== undefined &&
        recalculatedAverageEmissionsMCF !== null ? (
          <div>
            <table className="alarm-details-container-table ">
              <tr>
                <td>
                  <div className={styles.LikelyLeakLocationWrapper}>
                    {leakLocationName(siteGrid, incidentActualLeakSource)}
                  </div>
                </td>
                <td>
                  {displayTextMcfAndKg(
                    "mcf",
                    recalculatedAverageEmissionsMCF,
                    mcfToKg
                  )}
                </td>
              </tr>
            </table>
          </div>
            ) : null}
      </div>

      <div className={styles.marginTopBottom5} style={{ marginTop: 15 }}>
        <div>
          <b>*Incident Type</b>
        </div>

        <Select
          value={incidentType}
          onChange={(value) => setIncidentType(value)}
          style={{ marginTop: 5 }}
        >
          <Select.Option value="fugitive">Fugitive</Select.Option>
          <Select.Option value="operational">Operational</Select.Option>
          <Select.Option value="maintenance">Maintenance</Select.Option>
        </Select>

        <div className={styles.marginTopBottom5} style={{ marginTop: 15 }}>
          <div>
            <b>*Operator Notes</b>
          </div>
          <TextArea
            value={incidentNotes}
            onChange={(e) => setIncidentNotes(e.target.value)}
            style={{ marginTop: 5 }}
          />
        </div>
        <Button
          onClick={() => setShowRecalculateModal(true)}
          style={{
            padding: 5,
            marginTop: 5,
            marginBottom: 5,
          }}
          disabled={incidentActualLeakSource === undefined}
        >
          Recalculate
        </Button>
        {alarmType !== "High PPM" && isAdmin && (
          <Button
            onClick={() => setShowConfidenceModal(true)}
            style={{
              padding: 5,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Confidence
          </Button>
        )}
      </div>
      <Button
        type="primary"
        onClick={onSaveAlarmIncident}
        style={{ marginTop: 15 }}
      >
        Save
      </Button>
    </div>
  );
};

export default AlarmDetails;
