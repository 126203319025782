import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { Organization } from "./types";

interface GetSitesResponse {
  organizations: Organization[];
}

export const customerSitesApi = createApi({
  reducerPath: "customerSites",
  baseQuery,
  tagTypes: ["CustomerSites"],
  endpoints: (builder) => ({
    getCustomerSites: builder.query<Organization[], null>({
      query: () => "customerSites",
      transformResponse: (returnValue: GetSitesResponse) => {
        return returnValue.organizations;
      },
      providesTags: ["CustomerSites"],
    }),
  }),
});

export const { useGetCustomerSitesQuery } = customerSitesApi;
