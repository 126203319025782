import { Dispatch, FC, SetStateAction, useState } from "react";
import { useGetOrgUsersQuery } from "../../../store/users/api";
import { useGetSitesQuery } from "../../../store/sites/api";
import { Select, Modal, Spin } from "antd";
import SiteAlarmsConfig from "../SiteAlarmsConfig";
import { PostBulkAssignSiteAlarmsConfigPayload } from "../../../store/siteAlarmsConfig/types";
import { usePostBulkAssignSiteAlarmsConfigMutation } from "../../../store/siteAlarmsConfig/api";
import { mcfToKgEmissionInput } from "../../../helpers/unitsHelpers";
import { useGetGlobalConfigQuery } from "../../../store/globalConfig/api";
const { Option } = Select;

interface BulkAssignSiteAlarmsConfigProps {
  orgId: string;
  showBulkAssign: boolean;
  setShowBulkAssign: Dispatch<SetStateAction<boolean>>;
}

const BulkAssignSiteAlarmsConfig: FC<BulkAssignSiteAlarmsConfigProps> = ({
  orgId,
  showBulkAssign,
  setShowBulkAssign,
}) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const [postBulkAssignAlarms] = usePostBulkAssignSiteAlarmsConfigMutation();
  const [units, setUnits] = useState("MCF");
  const [recipients, setRecipients] = useState<string[]>([]);
  const [alertRecipients, setAlertRecipients] = useState<string[]>([]);
  const [concentrationRecipients, setConcentrationRecipients] = useState<
  string[]
  >([]);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);
  const [concentrationParams, setConcentrationParams] = useState({
    enabled: true,
  });
  const [alarmParams, setAlarmParams] = useState({
    hits: 3,
    sumOfDeviations: 5,
    title: "",
    description: "",
    rollingAverageConfig: 2,
    enabled: true,
  });
  const [alertParams, setAlertParams] = useState({
    hits: 3,
    sumOfDeviations: 3,
    title: "",
    description: "",
    rollingAverageConfig: 2,
    enabled: true,
  });
  const [gpaqsDatasetRangeInHours, setGpaqsDatasetRangeInHours] = useState(1);
  const [minimumDetectionThreshold, setMinimumDetectionThreshold] = useState(0);
  const orgUsersQuery = useGetOrgUsersQuery(orgId);
  const orgSitesQuery = useGetSitesQuery(orgId);
  const isLoading = orgUsersQuery.isLoading || orgSitesQuery.isLoading;
  let siteOptions: JSX.Element[] = [];
  if (orgSitesQuery.data != null && orgSitesQuery.data !== undefined) {
    siteOptions = orgSitesQuery.data.map((site) => {
      return (
        <Option value={site.SiteId} key={site.SiteId}>
          {site.name}
        </Option>
      );
    });
  }

  const onClickCancelBulkAssign = (): void => {
    setShowBulkAssign(false);
  };

  const onClickOkBulkAssign = (): void => {
    if (selectedSiteIds.length > 0) {
      const minimumDetectionThresholdMCF =
        units === "MCF"
          ? minimumDetectionThreshold
          : mcfToKgEmissionInput(
            minimumDetectionThreshold,
            "MCF",
            globalConfig
          );
      const payload: PostBulkAssignSiteAlarmsConfigPayload = {
        OrgId: orgId,
        siteIds: selectedSiteIds,
        alarmsConfig: {
          alarmParams,
          alertParams,
          concentrationParams,
          recipients,
          alertRecipients,
          concentrationRecipients,
          gpaqsDatasetRangeInHours,
          minimumDetectionThresholdMCF,
        },
      };
      postBulkAssignAlarms(payload)
        .unwrap()
        .then((response) => {
          window.alert("Bulk Assign Alarms successful");
          setShowBulkAssign(false);
        })
        .catch((err) => {
          window.alert(
            "Error while bulk assigning alarms. Please try again later or contact info@earthview.io"
          );
          console.log(err);
          setShowBulkAssign(false);
        });
    } else {
      window.alert("Please select a site to assign");
    }
  };

  const handleSiteChange = (selectedValues: string[]): void => {
    setSelectedSiteIds(selectedValues);
  };

  const onChangeAlarmParams = (
    newValue: number | string | boolean,
    valueKey: string
  ): void => {
    const _alarmParams = { ...alarmParams, [valueKey]: newValue };
    setAlarmParams(_alarmParams);
  };

  const onChangeAlertParams = (
    newValue: number | string | boolean,
    valueKey: string
  ): void => {
    const _alertParams = { ...alertParams, [valueKey]: newValue };
    setAlertParams(_alertParams);
  };

  const onChangeConcentrationParams = (
    newValue: number | string | boolean,
    valueKey: string
  ): void => {
    const _concentrationParams = {
      ...concentrationParams,
      [valueKey]: newValue,
    };
    setConcentrationParams(_concentrationParams);
  };

  let userEmails: string[] = [];

  if (orgUsersQuery.data != null && orgUsersQuery.data !== undefined) {
    userEmails = orgUsersQuery.data.map((user) => user.email);
  }

  return (
    <div>
      <Modal
        centered
        open={showBulkAssign}
        title="Bulk Assign Alarms"
        onCancel={onClickCancelBulkAssign}
        onOk={onClickOkBulkAssign}
        width={1200}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <div>
            <SiteAlarmsConfig
              units={units}
              setUnits={setUnits}
              alarmParams={alarmParams}
              alertParams={alertParams}
              concentrationParams={concentrationParams}
              recipients={recipients}
              concentrationRecipients={concentrationRecipients}
              alertRecipients={alertRecipients}
              onChangeAlarmParams={onChangeAlarmParams}
              onChangeAlertParams={onChangeAlertParams}
              onChangeConcentrationParams={onChangeConcentrationParams}
              onSaveAlarms={onClickOkBulkAssign}
              rollingAverages={null}
              userEmails={userEmails}
              onChangeRecipients={setRecipients}
              onChangeAlertRecipients={setAlertRecipients}
              onChangeConcentrationRecipients={setConcentrationRecipients}
              isBulkAssign={true}
              gpaqsDatasetRangeInHours={gpaqsDatasetRangeInHours}
              setGpaqsDatasetRangeInHours={setGpaqsDatasetRangeInHours}
              minimumDetectionThreshold={minimumDetectionThreshold}
              setMinimumDetectionThreshold={setMinimumDetectionThreshold}
              siteOptions={siteOptions}
              handleSiteChange={handleSiteChange}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BulkAssignSiteAlarmsConfig;
