import { FC } from "react";
import { Input, Form, Select, Radio, Tooltip } from "antd";
import styles from "./styles.module.scss";

const { Option } = Select;

const SiteConfig: FC = () => {
  return (
    <div className={styles.Form}>
      <h2>Site Config</h2>
      <Form.Item
        label="Algorithm"
        name="algorithm"
        rules={[{ required: true, message: "Please select the algorithm" }]}
      >
        <Select placeholder="Select a option and change input text above">
          <Option value={1}>Methane Only</Option>
          <Option value={2}>Mostly Methane</Option>
          <Option value={3}>Natural Gas</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Alarms" name="paqsAlarms">
        <Radio.Group>
          <Radio value={true}>Enabled</Radio>
          <Radio value={false}>Disabled</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Use Weather API?" name="usingAPI">
        <Radio.Group>
          <Radio value={true}>ON</Radio>
          <Radio value={false}>OFF</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Send Node Status Checks?" name="sendStatusChecks">
        <Radio.Group>
          <Radio value={true}>ON</Radio>
          <Radio value={false}>OFF</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Emission Units" name="emissionUnits">
        <Radio.Group>
          <Tooltip title="1000 Cubic Feet">
            <Radio value={"MCF"}>MCF</Radio>
          </Tooltip>
          <Tooltip title="Kilograms">
            <Radio value={"kg"}>kg</Radio>
          </Tooltip>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Variance 1 Cut"
        name="var1cut"
        rules={[{ required: true }]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Variance 3 Cut"
        name="var3cut"
        rules={[{ required: true }]}
      >
        <Input type="number" />
      </Form.Item>
    </div>
  );
};

export default SiteConfig;
