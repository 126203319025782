export const onPrintQRCodes = (): void => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  const modalElements = document.getElementsByClassName("qrCodeDiv");
  for (const modalContent of modalElements) {
    if (modalContent === null) {
      continue;
    }

    const clonedContent = modalContent.cloneNode(true) as HTMLElement;
    const originalStyle = window.getComputedStyle(modalContent);

    for (let i = 0; i < originalStyle.length; i++) {
      const propName = originalStyle[i];
      clonedContent.style.setProperty(
        propName,
        originalStyle.getPropertyValue(propName)
      );
    }

    if (iframe.contentWindow !== null) {
      const iframeDoc = iframe.contentWindow.document;
      iframeDoc.body.appendChild(clonedContent);
    }
  }

  setTimeout(() => {
    if (iframe.contentWindow !== null) {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }
  }, 1000);
};
