import { Button, Spin, Divider, Radio } from "antd";
import styles from "./styles.module.scss";
import { FC, useState } from "react";
import { useGetGlobalConfigQuery } from "../../../store/globalConfig/api";
import AlarmsTable from "../AlarmsTable/AlarmsTable";
import DatePickerWithArrows from "../../DatePickerWithArrows/DatePickerWithArrows";
import { LayerControllerProps } from "./types";
import type { RadioChangeEvent } from "antd";
import { useAppSelector } from "../../../store/hooks";
import { currentSiteRequireDroneImage } from "../../../store/sites/selector";
import { LayerSwitch } from "../../LayersSwitch/LayersSwitch";

const RED = "red";
const BLUE = "blue";
const GREEN = "#52C41A";
const LIGHT_GREY = "lightgrey";
const GREY = "grey";

const LayersController: FC<LayerControllerProps> = ({
  onToggleNodesLayer,
  onToggleGridLayer,
  onSelectAlarmIncident,
  onClearSelectedAlarm,
  selectedAlarmId,
  onDateChange,
  selectedDate,
  alarmIncidents,
  fetchingAlarms,
  onToggleActiveGridCells,
  timezone,
  onTogglePPMLayer,
  setShowWinds,
  showWinds,
  showPPMLayer,
  showNodesLayer,
  showGridLayer,
  showActiveGridCellsLayer,
  showGPAQSLayer,
  onToggleGPAQSLayer,
  isAdmin,
  selectedGPAQSLargestValue,
  onStartCreateNewEvent,
  toggleCaughtEventsLayer,
  showCaughtEvents,
  isSiteGridLoading,
  showAvgEmissions,
  onToggleAvgEmissions,
  showVOCLevels,
  setShowVOCLevels,
  showDroneImageLayer,
  setShowDroneImageLayer,
}) => {
  const requireDroneImage = useAppSelector(currentSiteRequireDroneImage);
  const [showMCFH, setShowMCFH] = useState(true);

  const [filterByCaughtEvents, setFilterByCaughtEvents] =
    useState<boolean>(false);

  const onChangeAlarmFilter = (e: RadioChangeEvent): void => {
    if (typeof e.target.value === "boolean") {
      setFilterByCaughtEvents(e.target.value);
    }
  };

  const globalConfig = useGetGlobalConfigQuery(null).data;
  const MCF_TO_KG = globalConfig?.mcfToKg ?? 1;
  const gpaqsValueDisplay = (
    selectedGPAQSLargestValue * (showMCFH ? 1 : MCF_TO_KG)
  ).toFixed(3);
  const gpaqsUnitDisplay = showMCFH ? "MCFH" : "kg/hr";

  const LegendItem = (text: string, color: string): JSX.Element => {
    return (
      <div className={styles.GPAQSLegendItem}>
        <div
          className={styles.ColoredSquare}
          style={{ backgroundColor: color }}
        />
        <span>{text}</span>
      </div>
    );
  };

  const handleToggleGPAQSUnit = (): void => {
    setShowMCFH(!showMCFH);
  };

  const filteredAlarms = filterByCaughtEvents
    ? alarmIncidents.filter(
      (alarmIncident) => alarmIncident.status !== undefined
    )
    : alarmIncidents;

  return (
    <div style={{ fontSize: 12 }}>
      {/* Layer Selection */}
      <div>
        {requireDroneImage
          ? LayerSwitch(
            "Drone Image",
            setShowDroneImageLayer,
            showDroneImageLayer
          )
          : null}
        {LayerSwitch("Sensors", onToggleNodesLayer, showNodesLayer)}
        {LayerSwitch(
          "Grid",
          onToggleGridLayer,
          showGridLayer,
          isSiteGridLoading
        )}
        {LayerSwitch(
          "Equipment",
          onToggleActiveGridCells,
          showActiveGridCellsLayer
        )}
        {LayerSwitch(
          "Avg. Emission Rate",
          onToggleAvgEmissions,
          showAvgEmissions
        )}
        {LayerSwitch("Max PPM", onTogglePPMLayer, showPPMLayer)}
        {LayerSwitch("VOC Levels", setShowVOCLevels, showVOCLevels)}
        {LayerSwitch("Winds", setShowWinds, showWinds)}
        {LayerSwitch(
          "Caught Events",
          toggleCaughtEventsLayer,
          showCaughtEvents
        )}
        {isAdmin
          ? LayerSwitch("GPAQS", onToggleGPAQSLayer, showGPAQSLayer)
          : null}
      </div>
      {/* GPAQS Display */}
      {isAdmin ? (
        <div>
          {showGPAQSLayer ? (
            <div className={styles.GPAQSLegend} onClick={handleToggleGPAQSUnit}>
              <div>
                {LegendItem("Acitve Cell", GREEN)}
                {LegendItem("Zero Emissions", GREY)}
                {LegendItem("Not Seen", LIGHT_GREY)}
              </div>
              {selectedGPAQSLargestValue > 0 ? (
                <div>
                  {LegendItem(`${gpaqsValueDisplay} ${gpaqsUnitDisplay}`, RED)}
                  {LegendItem(`0.000 ${gpaqsUnitDisplay}`, BLUE)}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      <Divider style={{ marginBottom: 15, marginTop: 15 }} />
      {/* Alarm Incidents */}
      <div>
        <div
          style={{
            height: 30,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Radio.Group
            onChange={onChangeAlarmFilter}
            value={filterByCaughtEvents}
            style={{ marginBottom: 16 }}
          >
            <Radio value={false}>Incidents</Radio>
            <Radio value={true}>Caught Events</Radio>
          </Radio.Group>
        </div>
        <div style={{ fontWeight: "bold" }}>Incidents</div>
        <div style={{ marginTop: 5, marginBottom: 10, display: "flex" }}>
          <DatePickerWithArrows
            onDateChange={onDateChange}
            selectedDate={selectedDate}
            size="small"
            style={{ width: 120 }}
          />
          <Button
            size="small"
            style={{ marginLeft: 5 }}
            type="primary"
            onClick={onClearSelectedAlarm}
          >
            Clear
          </Button>
          <Button
            size="small"
            style={{ marginLeft: 5 }}
            type="primary"
            onClick={onStartCreateNewEvent}
          >
            New
          </Button>
        </div>

        <div>
          {fetchingAlarms ? (
            <Spin />
          ) : (
            <AlarmsTable
              onSelectAlarmIncident={onSelectAlarmIncident}
              selectedAlarmId={selectedAlarmId}
              alarmIncidents={filteredAlarms}
              timezone={timezone}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LayersController;
