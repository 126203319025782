import { FC } from "react";
import { Chart as ChartJS } from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  NodeStatusCheck,
  StatusCheckObj,
} from "../../../store/statusChecks/types";
import { RawNodeSample } from "../../../store/rawNodeSamples/types";
import { NodePPM } from "../../../store/nodesPPM/types";
import { columnToAxisName } from "../../../pages/Analytics/columns";
import { DATA_TYPES, StatusChartProps } from "../../../pages/Analytics/types";
import { MyChartScales } from "../types";
import {
  intToRegressionModel,
  regressionModelToInt,
  statCheckToNumber,
  stringToColor,
} from "../helpers";
import styles from "../styles.module.scss";

ChartJS.register(zoomPlugin);

const BORDER_WIDTH = 1.3; // chartJS default is 2

const AnalyticsChart: FC<StatusChartProps> = ({
  menuSelections,
  timeSeriesData,
}) => {
  const title = "Analytics Hub";
  const labels: string[] = [];
  type FieldsData = Record<string, Array<number | string | null>>;
  const chartDatasets: Record<string, FieldsData> = {};
  for (const node of menuSelections.nodes) {
    const initialFieldsData: FieldsData = {};
    for (const col of menuSelections.columns) {
      initialFieldsData[col] = [];
    }
    chartDatasets[node.NodeId] = initialFieldsData;
  }

  for (const ele of timeSeriesData) {
    labels.push(ele.Timestamp);
    const nodeId = ele.NodeId;
    for (const node of menuSelections.nodes) {
      for (const col of menuSelections.columns) {
        let value: number | string | null = null;
        if (node.NodeId === nodeId) {
          value =
            ele[col as keyof (NodeStatusCheck | RawNodeSample | NodePPM)] ??
            null;

          if (
            menuSelections.dataType === DATA_TYPES.rawNodeSamples &&
            menuSelections.doFilterInvalidRawData
          ) {
            const invalidFieldsDetails =
              (ele as RawNodeSample).invalidFieldsDetails ?? {};
            if (Object.keys(invalidFieldsDetails).includes(col)) {
              value = null;
            }
          }

          if (menuSelections.dataType === DATA_TYPES.statusChecks) {
            value = statCheckToNumber(
              (ele as NodeStatusCheck).checks[col as keyof StatusCheckObj]
            );
          }

          if (col === "regressionModel") {
            value = regressionModelToInt(value as string);
          }
        }

        chartDatasets[node.NodeId][col].push(value);
      }
    }
  }

  const unit =
    menuSelections.dataType === DATA_TYPES.statusChecks
      ? ("day" as const)
      : ("hour" as const);

  const axisNames = new Set(
    menuSelections.columns.map((col) => columnToAxisName(col))
  );

  const options = {
    responsive: true,
    maintainAspectRaio: false,
    interaction: {
      intersect: false,
      mode: "index" as const,
    },
    plugins: {
      title: {
        display: true,
        text: title,
      },
      zoom: {
        zoom: {
          mode: "xy" as const,
          drag: {
            enabled: true,
          },
        },
      },
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  let count = 0;
  for (const id of axisNames) {
    (options.scales as MyChartScales)[id as keyof MyChartScales] = {
      type: "linear" as const,
      position: count % 2 === 1 ? "right" : "left",
      title: {
        display: true,
        text: id,
        font: {
          size: 20,
          weight: "bold",
        },
      },
      grid: {
        drawOnChartArea: count === 0,
      },
      ticks:
        id === "Regress Model"
          ? {
              callback: (value: number) => {
                return intToRegressionModel(value);
              },
            }
          : {},
    };
    ++count;
  }

  const datasets = [];
  for (const node of menuSelections.nodes) {
    for (const column of menuSelections.columns) {
      let label = column;
      const color = stringToColor(node.NodeId + column);
      if (menuSelections.nodes.length !== 1) {
        label = node.name + " - " + label;
      }

      const data = {
        label,
        yAxisID: columnToAxisName(column),
        data: chartDatasets[node.NodeId][column],
        backgroundColor: color,
        borderColor: color,
        borderWidth: BORDER_WIDTH,
        radius: 0,
        spanGaps: true,
      };

      datasets.push(data);
    }
  }

  const chartData = { labels, datasets };

  if (axisNames.size === 0) {
    return (
      <div className={styles.NoChartData}>
        <h2>Select nodes and data to display plot</h2>
      </div>
    );
  }

  return <Line options={options} data={chartData} />;
};

export default AnalyticsChart;
