import type { GlobalConfig } from "../store/globalConfig/types";

export const mcfToSiteEmissionUnits = (
  mcf: number,
  siteEmissionUnits: string,
  globalConfig: GlobalConfig | undefined
): number => {
  const MCF_TO_KG = globalConfig?.mcfToKg ?? 1;

  let mult = 1;
  switch (siteEmissionUnits) {
    case "MCF":
      mult = 1;
      break;
    case "kg":
      mult = MCF_TO_KG ?? 1;
      break;
  }

  const result = mult * mcf;
  if (result !== undefined) {
    return parseFloat(result.toFixed(3));
  }
  return 0;
};

export const mcfToKgEmissionInput = (
  value: number,
  desiredUnit: string,
  globalConfig: GlobalConfig | undefined
): number => {
  const MCF_TO_KG = globalConfig?.mcfToKg ?? 1;
  let conversion = MCF_TO_KG;
  switch (desiredUnit) {
    case "MCF":
      conversion = MCF_TO_KG ** -1;
      break;
    case "kg":
      conversion = MCF_TO_KG;
      break;
  }
  const result = conversion * value;
  if (result !== undefined) {
    return parseFloat(result.toFixed(3));
  }
  return 0;
};
