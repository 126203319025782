import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { NodePAQSResponse, NodePAQSParams } from "./types";

export const nodePAQSApi = createApi({
  reducerPath: "nodePAQS",
  baseQuery,
  endpoints: (builder) => ({
    getNodePAQS: builder.query<NodePAQSResponse, NodePAQSParams>({
      query: (payload) => {
        const { nodeId, startDate, endDate } = payload;
        const params = { startDate, endDate };
        return {
          url: `node/${nodeId}/nodePAQS`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});
