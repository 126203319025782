import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { LastLocationParams, LastLocationResponse } from "./types";

export const nodeLocationHistoryApi = createApi({
  reducerPath: "nodeLocationHistory",
  baseQuery,
  endpoints: (builder) => ({
    getLastNodeLocation: builder.query<
    LastLocationResponse,
    LastLocationParams
    >({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `nodes/${id}/lastLocation`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetLastNodeLocationQuery } = nodeLocationHistoryApi;
