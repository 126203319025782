import { DailyEmissionsMetaData } from "../../../store/dailyEmissions/types";
import { FC } from "react";
import styles from "./styles.module.scss";

interface DisplayBoxProps {
  dailyEmissionsMetaData: DailyEmissionsMetaData;
  units: string;
}
const DisplayBox: FC<DisplayBoxProps> = ({ dailyEmissionsMetaData, units }) => {
  return (
    <div className={styles.Container}>
      <div>
        <p className={styles.SevenDay}>{`7-Day Avg: ${dailyEmissionsMetaData.current7DayAvgMcf} ${units}/day`}</p>
        <p className={styles.NinetyDay}>{`90-Day Avg: ${dailyEmissionsMetaData.current90DayAvgMcf} ${units}/day`}</p>
      </div>
    </div>
  );
};

export default DisplayBox;
