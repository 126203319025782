import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { SingleNodeSitePAQSResponse, SingleNodeSitePAQSParams, AverageEmissionRatesResponse } from "./types";

export const singleNodeSitePAQSApi = createApi({
  reducerPath: "singleNodeSitePAQS",
  baseQuery,
  tagTypes: ["SingleNodeSitePAQS"],
  endpoints: (builder) => ({
    getSingleNodeSitePAQS: builder.query<
    SingleNodeSitePAQSResponse,
    SingleNodeSitePAQSParams
    >({
      query: (payload) => {
        const { siteId, startDate, endDate, timezone } = payload;
        const params = { startDate, endDate, timezone };
        return {
          url: `site/${siteId}/singleNodeSitePAQS`,
          method: "GET",
          params,
        };
      },
      providesTags: ["SingleNodeSitePAQS"]
    }),
    getGpaqsAverageEmissionRates: builder.query<AverageEmissionRatesResponse, SingleNodeSitePAQSParams>({
      query: (payload) => {
        const { siteId, startDate, endDate, timezone } = payload;
        const params = { startDate, endDate, timezone };
        return {
          url: `site/${siteId}/averageEmissionRates`,
          method: "GET",
          params,
        };
      },
      providesTags: ["SingleNodeSitePAQS"]
    })
  }),
});
