import { FC, useState, useEffect } from "react";
import { Button } from "antd";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getDurationHours } from "../../components/TimeRangePicker/helpers";
import { statusChecksApi } from "../../store/statusChecks/api";
import { rawNodeSamplesApi } from "../../store/rawNodeSamples";
import { nodesPPMApi } from "../../store/nodesPPM/api";
import { NodePPM } from "../../store/nodesPPM/types";
import { organizationsApi } from "../../store/organizations/api";
import { Organization } from "../../store/organizations/types";
import { setCurrentOrganization } from "../../store/organizations/slice";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import {
  startTimestampSelector,
  endTimestampSelector,
} from "../../store/selections/selector";
import { sitesApi } from "../../store/sites/api";
import { Site } from "../../store/sites/types";
import { setCurrentSite } from "../../store/sites/slice";
import { currentSiteSelector } from "../../store/sites/selector";
import { Node } from "../../store/nodes/types";
import { siteNodesApi } from "../../store/nodes/api";
import { NodeStatusCheck } from "../../store/statusChecks/types";
import { RawNodeSample } from "../../store/rawNodeSamples/types";
import AnalyticsChart from "../../components/AnalyticsComponents/Chart/AnalyticsChart";
import Menu from "../../components/AnalyticsComponents/Menu/AnalyticsMenu";
import { DATA_TYPES, MenuSelections } from "./types";
import styles from "./styles.module.scss";

export const EARTHVIEW_WAREHOUSE = "Earthview Warehouse";

const Analytics: FC = () => {
  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const currentSite = useAppSelector(currentSiteSelector);
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);

  const timezone = currentSite?.timezone ?? "";

  const initialDurationHoursTotal = getDurationHours(
    startTimestamp,
    endTimestamp
  );
  const initialDurationHours = initialDurationHoursTotal % 24;
  const initialDurationDays = Math.floor(initialDurationHoursTotal / 24);

  const initialMenuSelectionState = {
    dataType: DATA_TYPES.rawNodeSamples,
    org: currentOrg,
    site: currentSite,
    nodes: [],
    columns: [],
    startTimestamp,
    durationHours: initialDurationHours,
    durationDays: initialDurationDays,
    doFilterInvalidRawData: true,
  };
  const [menuSelections, setMenuSelections] = useState<MenuSelections>(
    initialMenuSelectionState
  );
  const [showMenu, setShowMenu] = useState(true);
  const [showLocalTime, setShowLocalTime] = useState(true);

  const [triggerGetOrgs, orgsResult] =
    organizationsApi.endpoints.getOrganizations.useLazyQuery();
  const [triggerGetSites, sitesResult] =
    sitesApi.endpoints.getSites.useLazyQuery();
  const [triggerGetNodes, nodesResult] =
    siteNodesApi.endpoints.getSiteNodes.useLazyQuery();
  const [triggerGetNodeStatusChecksTime, nodeStatusChecksTimeResult] =
    statusChecksApi.endpoints.getStatusChecks.useLazyQuery();
  const [triggerGetRawSamples, rawSamplesResult] =
    rawNodeSamplesApi.endpoints.getRawNodeSamples.useLazyQuery();
  const [triggerGetNodePPM, nodePPMResult] =
    nodesPPMApi.endpoints.getNodesPPM.useLazyQuery();

  const orgs = orgsResult?.data ?? [];
  const sites =
    sitesResult?.data?.filter((site: Site) => site.isSingleNode) ?? [];
  const nodes = nodesResult?.data?.result ?? [];

  let timeSeriesData: Array<NodeStatusCheck | RawNodeSample | NodePPM> = [];
  switch (menuSelections.dataType) {
    case DATA_TYPES.statusChecks:
      timeSeriesData = nodeStatusChecksTimeResult?.data?.result ?? [];
      break;
    case DATA_TYPES.rawNodeSamples:
      timeSeriesData = rawSamplesResult?.data?.result ?? [];
      break;
    case DATA_TYPES.nodePPM:
      timeSeriesData = nodePPMResult?.data?.result ?? [];
      break;
  }

  const handleDoFilterInvalidRawDataChange = (): void => {
    setMenuSelections((menuSelections: MenuSelections) => {
      return {
        ...menuSelections,
        doFilterInvalidRawData: !menuSelections.doFilterInvalidRawData,
      };
    });
  };

  const handleChangeDataType = (newDataType: string): void => {
    setMenuSelections((menuSelections: MenuSelections) => {
      return {
        ...menuSelections,
        dataType: newDataType,
        columns: [],
      };
    });
  };

  const handleChangeOrg = async (newOrgName: string): Promise<void> => {
    const newOrg = orgs.find((org: Organization) => org.name === newOrgName);
    let newSite: Site | null = null;

    if (newOrg === undefined) return;

    if (newOrg.name === EARTHVIEW_WAREHOUSE) {
      const getSitesResponse = await triggerGetSites(newOrg.OrgId);
      const sites = getSitesResponse?.data ?? [];
      const [testingSite] = sites.filter(
        (site: Site) => site.SiteId === "UNASSIGNED"
      );
      newSite = testingSite;
      dispatch(setCurrentSite(testingSite));
    }

    setMenuSelections((menuSelections: MenuSelections) => {
      return {
        ...menuSelections,
        org: newOrg,
        site: newSite,
        nodes: [],
      };
    });
    dispatch(setCurrentSite(newSite));
    dispatch(setCurrentOrganization(newOrg));
  };

  const handleChangeSite = (newSiteId: string): void => {
    const newSite = sites.find((site: Site) => site.SiteId === newSiteId);
    if (newSite === undefined) return;
    setMenuSelections((menuSelections: MenuSelections) => {
      return {
        ...menuSelections,
        site: newSite,
        nodes: [],
      };
    });
    dispatch(setCurrentSite(newSite));
  };

  const handleChangeNodes = (newNodeIds: string[]): void => {
    const newNodes: Node[] = [];
    newNodeIds.forEach((nodeId: string) => {
      const newNode = nodes.find((node: Node) => node.NodeId === nodeId);
      if (newNode !== undefined) {
        newNodes.push(newNode);
      }
    });
    let newColumns = menuSelections.columns;
    if (newNodeIds.length > 1) {
      newColumns = menuSelections.columns.slice(0, 2);
    }
    setMenuSelections((menuSelections: MenuSelections) => {
      return {
        ...menuSelections,
        nodes: newNodes,
        columns: newColumns,
      };
    });
  };

  const handleChangeColumns = (newColumns: string[]): void => {
    setMenuSelections((menuSelections: MenuSelections) => {
      return {
        ...menuSelections,
        columns: newColumns,
      };
    });
  };

  const handleChangeShowLocalTime = (newValue: boolean): void => {
    setShowLocalTime(newValue);
  };

  useEffect(() => {
    void triggerGetOrgs(null);
  }, []);

  useEffect(() => {
    const orgId = menuSelections?.org?.OrgId ?? "";
    void triggerGetSites(orgId);
  }, [menuSelections.org]);

  useEffect(() => {
    const siteId = menuSelections?.site?.SiteId ?? "";
    void triggerGetNodes({ id: siteId });
  }, [menuSelections.site]);

  useEffect(() => {
    handleChangeColumns([]);
  }, [menuSelections.dataType]);

  useEffect(() => {
    const nodeIdsString = menuSelections.nodes
      .map((node: Node) => node.NodeId)
      .join(",");

    let requestedFields = menuSelections.columns.join(",");
    if (menuSelections.dataType === DATA_TYPES.rawNodeSamples) {
      requestedFields += ",invalidFieldsDetails";
    }

    if (menuSelections.columns.length > 0) {
      switch (menuSelections.dataType) {
        case DATA_TYPES.rawNodeSamples:
          void triggerGetRawSamples({
            id: menuSelections.nodes[0]?.NodeId ?? "",
            nodeIds: nodeIdsString,
            startDate: startTimestamp,
            endDate: endTimestamp,
            timezone: showLocalTime ? timezone : "UTC",
            requestedFields,
          });
          break;
        case DATA_TYPES.nodePPM:
          void triggerGetNodePPM({
            id: currentSite?.SiteId ?? "",
            nodeIds: nodeIdsString,
            startDate: startTimestamp,
            endDate: endTimestamp,
            timezone: showLocalTime ? timezone : "UTC",
            requestedFields,
          });
          break;
        case DATA_TYPES.statusChecks:
          void triggerGetNodeStatusChecksTime({
            id: menuSelections.nodes[0]?.NodeId ?? "",
            nodeIds: nodeIdsString,
            startDate: startTimestamp,
            endDate: endTimestamp,
            timezone: showLocalTime ? timezone : "UTC",
          });
          break;
      }
    }
  }, [
    currentOrg,
    currentSite,
    menuSelections,
    startTimestamp,
    endTimestamp,
    showLocalTime,
  ]);

  return (
    <div className={styles.AnalyticsWrapper}>
      <div className={styles.ButtonWrapper}>
        <Button onClick={() => setShowMenu(!showMenu)}>
          {showMenu ? "Hide Menu" : "Show Menu"}
        </Button>
      </div>
      {showMenu ? (
        <div className={styles.SelectionWrapper}>
          <Menu
            menuSelections={menuSelections}
            orgs={orgs}
            handleChangeOrg={handleChangeOrg}
            sites={sites}
            handleChangeSite={handleChangeSite}
            nodes={nodes}
            handleChangeNodes={handleChangeNodes}
            handleChangeColumns={handleChangeColumns}
            handleChangeDataType={handleChangeDataType}
            handleDoFilterInvalidRawDataChange={
              handleDoFilterInvalidRawDataChange
            }
            showLocalTime={showLocalTime}
            handleChangeShowLocalTime={handleChangeShowLocalTime}
            timezone={timezone}
          />
        </div>
      ) : null}
      <div className={styles.ChartWrapper}>
        <AnalyticsChart
          menuSelections={menuSelections}
          timeSeriesData={timeSeriesData}
        />
      </div>
    </div>
  );
};

export default Analytics;
