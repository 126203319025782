import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import { Incident } from "./types";
import { Site } from "../sites/types";

interface GetOrgAlarmIncidentsParams {
  orgId: string;
  date: string;
}

export interface GetOrgAlarmIncidentsResult {
  orgAlarmIncidents: Incident[];
  sites: Site[];
}

export const orgAlarmIncidentsApi = createApi({
  reducerPath: "orgAlarmIncidentsApi",
  baseQuery,
  tagTypes: ["orgAlarmIncidents"],
  endpoints: (builder) => ({
    getOrgAlarmIncidents: builder.query<
    GetOrgAlarmIncidentsResult,
    GetOrgAlarmIncidentsParams
    >({
      query: (params: GetOrgAlarmIncidentsParams) =>
        `orgAlarmIncidents/${params.orgId}?date=${params.date}`,
      providesTags: ["orgAlarmIncidents"],
    }),
  }),
});

export const { useLazyGetOrgAlarmIncidentsQuery } = orgAlarmIncidentsApi;
