// Styles must be in-line for the pdf print functionality to work

import { FC } from "react";
import styles from "../../styles.module.scss";
import qrPrinterTemplate from "../../../../assets/printer_template.png";
import { QrCodePagesProps } from "../../types";

const QrCodePages: FC<QrCodePagesProps> = ({
  qrCodes,
  selectedRowKeys: selectedNodeIds,
}) => {
  const qrCodePrintout = (qrCodes: string[]): JSX.Element => {
    return (
      <div className="qrCodeDiv" style={{ position: "relative" }}>
        <img
          src={qrPrinterTemplate}
          style={{ height: "100%", width: "100%" }}
        />
        {qrCodes.map((dataURL, index) => (
          <div
            key={index}
            className={styles.QrCodeWrapper}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              bottom: `${784 - 185 * Math.floor(index / 4)}px`,
              left: `${24 + 186 * (index % 4)}px`,
            }}
          >
            <img
              src={dataURL}
              height="180px"
              style={{
                display: "block",
              }}
            />
            <span
              style={{
                position: "absolute",
                top: "160px",
              }}
            >
              {selectedNodeIds[index]}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const itemsPerPage = 20; // 4 cols, 5 rows
  const numPages = Math.ceil(qrCodes.length / itemsPerPage);

  const qrCodePages = [];
  for (let i = 0; i < numPages; i++) {
    const pageCodes = qrCodes.slice(i * itemsPerPage, (i + 1) * itemsPerPage);
    const pageElement = qrCodePrintout(pageCodes);
    qrCodePages.push(pageElement);
  }

  return <div>{qrCodePages}</div>;
};

export default QrCodePages;
