import { FC } from "react";
import { Form, Input, Switch, Button, DatePicker } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setPageTitle } from "../../../store/ui/slice";
import { setCurrentNode } from "../../../store/nodes/slice";
import { currentSiteSelector } from "../../../store/sites/selector";
import {
  useAssignNodeMutation,
  useUpdateSiteNodeMutation,
} from "../../../store/nodes/api";
import { useGetLastNodePPMReadQuery } from "../../../store/nodesPPM/api";
import { useGetLastNodeLocationQuery } from "../../../store/nodeLocationHistory/api";
import { getUtcDayjs } from "../../../helpers/dayjsHelpers";
import { utcTimestampToLocal } from "../../../helpers/dateTimeHelpers";
import { EditFormValues, NodeFormProps, NodeFormSubmitPayload } from "../types";
import styles from "../styles.module.scss";

const EditForm: FC<NodeFormProps> = ({
  node,
  isAssign = false,
  onSubmit = () => {
    /* placeholder */
  },
  onCancel = () => {
    /* placeholder */
  },
  test = false,
}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dispatch = useAppDispatch();
  const currentSite = useAppSelector(currentSiteSelector);
  const [assignNode] = useAssignNodeMutation();
  const [updateNode] = useUpdateSiteNodeMutation();

  const doAssignNode = (payload: NodeFormSubmitPayload): void => {
    assignNode({ node: payload })
      .unwrap()
      .then((res) => {
        if (res?.Message === "Success") {
          window.alert("Node Assigned Successfully");
          onSubmit();
        } else {
          window.alert("Node Assignment Failed");
        }
      })
      .catch((err) => {
        console.log("Error assigning node:\n", err);
        window.alert("Node Assignment Failed");
      });
  };

  const doEditNode = (payload: NodeFormSubmitPayload): void => {
    updateNode(payload)
      .unwrap()
      .then((response) => {
        if (response?.result?.Message === "SUCCESS") {
          window.alert("Node Saved Successfully");
          onSubmit();
          dispatch(setCurrentNode(payload));
          dispatch(setPageTitle(`Edit Node - ${payload.name}`));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const nodeId = node?.NodeId ?? "";
  const nodeMacAddress = node?.MacAddress ?? nodeId;
  const newSiteId = currentSite?.SiteId ?? "";
  const oldSiteId = node?.SiteId ?? "";
  const orgId = currentSite?.OrgId ?? "";
  const latitude = node?.latitude ?? "";
  const longitude = node?.longitude ?? "";
  const sampleHeight = node?.sampleHeight ?? "";
  const elevation = node?.elevation ?? "";
  const name = node?.name ?? "";
  const active = node?.active ?? "";
  const installDate = node?.installDate ?? "";
  const useWind = node?.useWind ?? true;

  const NA = "N/A";

  const lastNodePPMReadResult = useGetLastNodePPMReadQuery({ id: nodeId });
  const lastNodePPMRead = lastNodePPMReadResult?.data?.result ?? null;
  const lastReadTimestampUTC = lastNodePPMRead?.Timestamp ?? NA;
  const lastReadLocalDate = utcTimestampToLocal(lastReadTimestampUTC);
  let lastReadTimestampLocal =
    lastReadTimestampUTC !== NA && lastReadLocalDate !== null
      ? lastReadLocalDate + " " + timeZone
      : NA;

  const lastNodeLocationResult = useGetLastNodeLocationQuery({ id: nodeId });
  const [lastNodeLocation] = lastNodeLocationResult?.data?.result ?? [null];
  const lastLocationTimestampUTC = lastNodeLocation?.Timestamp ?? NA;
  const lastLocationLocalDate = utcTimestampToLocal(lastLocationTimestampUTC);
  const lastLocationLat = lastNodeLocation?.latitude ?? "";
  const lastLocationLon = lastNodeLocation?.longitude ?? "";
  let lastLocationTimestampLocal =
    lastLocationTimestampUTC !== NA && lastLocationLocalDate !== null
      ? lastLocationLocalDate
      : NA;

  const formInstallDate =
    node?.installDate !== null &&
    node?.installDate !== undefined &&
    installDate !== "UNASSIGNED"
      ? getUtcDayjs(node.installDate)
      : null;

  const initialValues = {
    name,
    elevation,
    sampleHeight,
    latitude,
    longitude,
    active,
    installDate: formInstallDate,
    useWind,
  };

  const onFinish = (values: EditFormValues): void => {
    const payload = {
      NodeId: nodeId,
      SiteId: newSiteId,
      name: values.name,
      active: values.active,
      elevation: values.elevation,
      latitude: values.latitude,
      longitude: values.longitude,
      sampleHeight: values.sampleHeight,
      MacAddress: nodeMacAddress,
      OrgId: orgId,
      installDate: values.installDate,
      oldSiteId,
      useWind: values.useWind,
    };
    if (isAssign) {
      doAssignNode(payload);
    } else {
      doEditNode(payload);
    }
  };

  const onFinishFailed = (error: ValidateErrorEntity): void => {
    console.log("Failed to submit:", error);
  };

  if (test) {
    const testText = "1/1/2024 00:00:00 America/Denver";
    lastReadTimestampLocal = testText;
    lastLocationTimestampLocal = testText;
  }

  return (
    <Form
      name="basic"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      wrapperCol={{ span: 6 }}
    >
      <div style={{ padding: "4px" }}>
        <span>
          <em>
            <b>Last read: </b>
            {lastReadTimestampLocal}
          </em>
        </span>
        <br />
        <span>
          <em>
            <b>Last location: </b>
            {lastLocationTimestampLocal}
          </em>
        </span>
        <span style={{ marginLeft: "16px" }}>
          {lastLocationTimestampLocal !== NA ? (
            <em>
              ({lastLocationLat}, {lastLocationLon})
            </em>
          ) : null}
        </span>
      </div>
      <Form.Item
        label={
          <span>
            Node Name
            <br />
            <span style={{ fontSize: "12px", color: "silver" }}>
              example - NW: DVN MLML
            </span>
          </span>
        }
        name="name"
        rules={[{ required: true, message: "Please input the node name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Elevation (ft)"
        name="elevation"
        rules={[{ required: true, message: "Please input the node elevation" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Sample Height (ft above ground)"
        name="sampleHeight"
        rules={[
          { required: true, message: "Please input the node sample height" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <span>
            Latitude
            <br />
            <span style={{ fontSize: "12px", color: "silver" }}>
              example: 35.529652
            </span>
          </span>
        }
        name="latitude"
        rules={[{ required: true, message: "Please input the node latitude" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <span>
            Longitude
            <br />
            <span style={{ fontSize: "12px", color: "silver" }}>
              example: -98.124321
            </span>
          </span>
        }
        name="longitude"
        rules={[{ required: true, message: "Please input the node longitude" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Installation Date" name="installDate">
        <DatePicker value={formInstallDate} />
      </Form.Item>

      <Form.Item label="Active" name="active" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="Use Wind" name="useWind">
        <Switch />
      </Form.Item>

      <Form.Item className={styles.FormFooter}>
        <div className={styles.Flex}>
          <Button style={{ marginRight: 10 }} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditForm;
