export const mcfhKgConversion = (
  units: string,
  value: number,
  MCF_TO_KG: number
): number => {
  const num = Number(value);
  const target = units === "kg" ? "MCFH" : "kg";
  let output = num;
  if (target === "kg") {
    output = MCF_TO_KG * num;
  }
  if (target === "MCFH") {
    output = num / MCF_TO_KG;
  }

  return output;
};

const roundTo3Decimals = (value: string): string =>
  parseFloat(value).toFixed(3);

export const displayTextMcfAndKg = (
  units: string,
  value: number,
  mcfToKg: number
): string => {
  const converted = roundTo3Decimals(
    `${mcfhKgConversion(units, value, mcfToKg)}`
  );
  const originalRounded = roundTo3Decimals(`${value}`);
  if (units === "mcf") {
    const output = `${originalRounded} MCFH | ${converted} kg/hr`;
    return output;
  } else {
    const output = `${converted} MCFH | ${originalRounded} kg/hr`;
    return output;
  }
};
