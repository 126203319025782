import { FC, useState, useEffect } from "react";
import {
  useCreateSiteAlarmsConfigMutation,
  useGetSiteAlarmsConfigQuery,
} from "../../store/siteAlarmsConfig/api";
import { currentSiteSelector } from "../../store/sites/selector";
import { useAppSelector } from "../../store/hooks";
import AlarmsConfig from "../../components/SiteAlarmsConfig/SiteAlarmsConfig";
import { Spin } from "antd";
import { useGetGpaqsRollingAveragesQuery } from "../../store/gpaqsRollingAverages/api";
import { useGetOrgUsersQuery } from "../../store/users/api";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import {
  mcfToSiteEmissionUnits,
  mcfToKgEmissionInput,
} from "../../helpers/unitsHelpers";
import { useGetGlobalConfigQuery } from "../../store/globalConfig/api";

interface SiteAlarmsConfigProps {
  units: string;
  setUnits: (newValue: string) => void;
}

const SiteAlarmsConfig: FC<SiteAlarmsConfigProps> = ({ units, setUnits }) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const [recipients, setRecipients] = useState<string[]>([]);
  const [alertRecipients, setAlertRecipients] = useState<string[]>([]);
  const [concentrationRecipients, setConcentrationRecipients] = useState<
  string[]
  >([]);
  const [concentrationParams, setConcentrationParams] = useState({
    enabled: true,
  });
  const [alarmParams, setAlarmParams] = useState({
    hits: 3,
    sumOfDeviations: 1,
    title: "",
    description: "",
    rollingAverageConfig: 1,
    enabled: true,
  });
  const [alertParams, setAlertParams] = useState({
    hits: 3,
    sumOfDeviations: 3,
    title: "",
    description: "",
    rollingAverageConfig: 1,
    enabled: true,
  });
  const [gpaqsDatasetRangeInHours, setGpaqsDatasetRangeInHours] = useState(1);
  const [minimumDetectionThreshold, setMinimumDetectionThreshold] = useState(0);
  const currentSite = useAppSelector(currentSiteSelector);
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const siteId = currentSite !== null ? currentSite.SiteId : "";
  const orgId = currentOrg !== null ? currentOrg.OrgId : "";
  const { isError, isLoading, data } = useGetSiteAlarmsConfigQuery(siteId);
  const [createSiteAlarms] = useCreateSiteAlarmsConfigMutation();
  const gpaqsRollingAveragesQuery = useGetGpaqsRollingAveragesQuery({ siteId });
  const orgUsersQuery = useGetOrgUsersQuery(orgId);
  let gpaqsRollingAverages = null;

  if (gpaqsRollingAveragesQuery.data !== undefined) {
    gpaqsRollingAverages = gpaqsRollingAveragesQuery.data;
  }

  const onChangeAlarmParams = (
    newValue: number | string | boolean,
    valueKey: string
  ): void => {
    const _alarmParams = { ...alarmParams, [valueKey]: newValue };
    setAlarmParams(_alarmParams);
  };

  const onChangeAlertParams = (
    newValue: number | string | boolean,
    valueKey: string
  ): void => {
    const _alertParams = { ...alertParams, [valueKey]: newValue };
    setAlertParams(_alertParams);
  };

  const onChangeConcentrationParams = (
    newValue: number | string | boolean,
    valueKey: string
  ): void => {
    const _concentrationParams = {
      ...concentrationParams,
      [valueKey]: newValue,
    };
    setConcentrationParams(_concentrationParams);
  };

  const onSaveAlarms = (): void => {
    const minimumDetectionThresholdMCF =
      units === "MCF"
        ? minimumDetectionThreshold
        : mcfToKgEmissionInput(minimumDetectionThreshold, "MCF", globalConfig);
    if (siteId !== undefined) {
      const alarmsConfig = {
        SiteId: siteId,
        alarmParams,
        alertParams,
        concentrationParams,
        recipients,
        concentrationRecipients,
        alertRecipients,
        gpaqsDatasetRangeInHours,
        minimumDetectionThresholdMCF,
      };
      createSiteAlarms(alarmsConfig)
        .unwrap()
        .then(() => {
          window.alert("Alarms configuration saved");
        })
        .catch((err) => {
          window.alert(
            "Error while updating alarms. Please try again later or contact the admin"
          );
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!isError && !isLoading && data !== undefined && data !== null) {
      setRecipients(data.recipients);
      setConcentrationRecipients(data.concentrationRecipients);
      setAlertRecipients(data.alertRecipients);
      setAlarmParams(data.alarmParams);
      setAlertParams(data.alertParams);
      setConcentrationParams(data.concentrationParams);
      if (data.gpaqsDatasetRangeInHours !== undefined) {
        setGpaqsDatasetRangeInHours(data.gpaqsDatasetRangeInHours);
      }
      if (data.minimumDetectionThresholdMCF !== undefined) {
        const mcf = data.minimumDetectionThresholdMCF;
        setMinimumDetectionThreshold(
          mcfToSiteEmissionUnits(mcf, units, globalConfig)
        );
      }
    }
  }, [data]);

  let userEmails: string[] = [];

  if (orgUsersQuery.data != null && orgUsersQuery.data !== undefined) {
    userEmails = orgUsersQuery.data.map((user) => user.email);
  }

  return isLoading ? (
    <Spin />
  ) : (
    <div>
      <AlarmsConfig
        units={units}
        setUnits={setUnits}
        alarmParams={alarmParams}
        alertParams={alertParams}
        concentrationParams={concentrationParams}
        recipients={recipients}
        concentrationRecipients={concentrationRecipients}
        alertRecipients={alertRecipients}
        onChangeAlarmParams={onChangeAlarmParams}
        onChangeAlertParams={onChangeAlertParams}
        onChangeConcentrationParams={onChangeConcentrationParams}
        onSaveAlarms={onSaveAlarms}
        rollingAverages={gpaqsRollingAverages}
        userEmails={userEmails}
        onChangeRecipients={setRecipients}
        onChangeAlertRecipients={setAlertRecipients}
        onChangeConcentrationRecipients={setConcentrationRecipients}
        gpaqsDatasetRangeInHours={gpaqsDatasetRangeInHours}
        setGpaqsDatasetRangeInHours={setGpaqsDatasetRangeInHours}
        minimumDetectionThreshold={minimumDetectionThreshold}
        setMinimumDetectionThreshold={setMinimumDetectionThreshold}
      />
    </div>
  );
};

export default SiteAlarmsConfig;
