import { FC } from "react";
import styles from "../../styles.module.scss";
import { mcfhKgConversion, displayTextMcfAndKg } from "../../helpers";

interface LocationEstimatesProps {
  leakRateMCFH: number;
  mcfToKg: number;
  upperErrorMCFH?: string;
  lowerErrorMCFH?: string;
}

const UncertaintyDiv = (
  upperError: number,
  lowerError: number
): JSX.Element => {
  return (
    <div className={styles.frac}>
      <span>{upperError.toFixed(2)}</span>
      <span className={styles.symbol}>/</span>
      <span className={styles.bottom}>{lowerError.toFixed(2)}</span>
    </div>
  );
};

const LocationEstimates: FC<LocationEstimatesProps> = ({
  leakRateMCFH,
  mcfToKg,
  upperErrorMCFH,
  lowerErrorMCFH,
}) => {
  const doDisplayUncertainty =
    upperErrorMCFH !== undefined && lowerErrorMCFH !== undefined;
  const leakRateKgHr = mcfhKgConversion("MCFH", leakRateMCFH, mcfToKg);
  const upperErrorMCFHNumber = doDisplayUncertainty
    ? Number(upperErrorMCFH)
    : 0;
  const lowerErrorMCFHNumber = doDisplayUncertainty
    ? Number(lowerErrorMCFH)
    : 0;
  const upperErrorKgHr = doDisplayUncertainty
    ? mcfhKgConversion("MCFH", upperErrorMCFHNumber, mcfToKg)
    : 0;
  const lowerErrorKgHr = doDisplayUncertainty
    ? mcfhKgConversion("MCFH", lowerErrorMCFHNumber, mcfToKg)
    : 0;
  return (
    <div>
      {doDisplayUncertainty ? (
        <div>
          {leakRateMCFH.toFixed(2)} &plusmn;{" "}
          {UncertaintyDiv(upperErrorMCFHNumber, lowerErrorMCFHNumber)} MCFH{" "}
          <br></br> {leakRateKgHr.toFixed(2)} &plusmn;{" "}
          {UncertaintyDiv(upperErrorKgHr, lowerErrorKgHr)} kg/hr
        </div>
      ) : (
        <div>{displayTextMcfAndKg("mcf", leakRateMCFH, mcfToKg)}</div>
      )}
    </div>
  );
};

export default LocationEstimates;
