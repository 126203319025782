import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SiteForm from "../../../components/SiteForm/SiteForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { currentOrganizationSelector } from "../../../store/organizations/selector";
import { useCreateSiteMutation } from "../../../store/sites/api";
import { Site } from "../../../store/sites/types";
import { setPageTitle } from "../../../store/ui/slice";
import { NewSiteFormResult, APIResponse } from "./types";
import styles from "./styles.module.scss";
import * as uuid from "uuid";

interface NewSiteProps {
  test?: boolean;
}

const NewSite: FC<NewSiteProps> = ({ test = false }) => {
  const dispatch = useAppDispatch();
  const currentOrganization = useAppSelector(currentOrganizationSelector);
  const navigate = useNavigate();
  const [createSite] = useCreateSiteMutation();

  const initialFormValues = {
    SiteId: test ? "Test ID" : uuid.v4(),
    siteName: "",
    timezone: "",
    algorithm: 3,
    flagTrip: 0.0,
    hitOf6: 4,
    paqsAlarms: true,
    ppmCO: 3,
    stallP: false,
    stallA: false,
    stallB: false,
    usingAPI: false,
    var1cut: 60000,
    var3cut: 60000,
    emissionUnits: "kg",
    latAPI: "30",
    longAPI: "-90",
    requireDroneImage: false,
    sendStatusChecks: true,
    isMultipleGrid: false,
  };

  const onFinish = (values: NewSiteFormResult): void => {
    if (currentOrganization !== null) {
      const site: Site = {
        OrgId: currentOrganization.OrgId,
        SiteId: values.SiteId,
        name: values.siteName,
        timezone: values.timezone,
        active: false,
        isSingleNode: true,
        requireDroneImage: false,
        sendStatusChecks: true,
        isMultipleGrid: values.isMultipleGrid,
        config: {
          algorithm: values.algorithm,
          ppmCO: values.ppmCO,
          usingAPI: values.usingAPI,
          hitOf6: values.hitOf6,
          flagTrip: values.flagTrip,
          paqsAlarms: values.paqsAlarms,
          var1cut: values.var1cut,
          var3cut: values.var3cut,
          stallP: values.stallP,
          stallA: values.stallA,
          stallB: values.stallB,
          emissionUnits: values.emissionUnits,
        },
        details: {
          latAPI: values.latAPI,
          longAPI: values.longAPI,
        },
      };
      createSite(site)
        .unwrap()
        .then((response: APIResponse | null) => {
          if (
            response !== null &&
            response.Message === "SUCCESS" &&
            currentOrganization !== null
          ) {
            window.alert("Site Created Successfully");
            navigate(`/organization/${currentOrganization.OrgId}/sites`);
          }
        })
        .catch((err) => {
          console.log(err);
          window.alert(
            "Error while creating site. Please try again later or contact Earthview"
          );
        });
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    console.log("Failed:", errorInfo);
  };

  const onCancel = (): void => {
    if (currentOrganization !== null) {
      navigate(`/organization/${currentOrganization.OrgId}/sites`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (currentOrganization !== null) {
      dispatch(setPageTitle(`New Site - ${currentOrganization.name}`));
    } else {
      dispatch(setPageTitle("New Site"));
    }
  }, []);

  return (
    <div className={styles.SiteFormWrapper}>
      <SiteForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onCancel={onCancel}
        initialValues={initialFormValues}
      />
    </div>
  );
};

export default NewSite;
