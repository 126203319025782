import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  NodePPMParams,
  NodePPMResponse,
  LastNodePPMParams,
  LastNodePPMResponse,
} from "./types";

export const nodesPPMApi = createApi({
  reducerPath: "nodesPPMAPI",
  baseQuery,
  tagTypes: ["NodesPPM"],
  endpoints: (builder) => ({
    getNodesPPM: builder.query<NodePPMResponse, NodePPMParams>({
      query: (payload) => {
        const {
          id,
          startDate,
          endDate,
          nodeIds,
          timezone,
          isGraph,
          requestedFields,
        } = payload;
        const params = {
          startDate,
          endDate,
          nodeIds,
          timezone,
          isGraph,
          requestedFields,
        };
        return {
          url: `site/${id}/nodesPPM`,
          method: "GET",
          params,
        };
      },
      providesTags: ["NodesPPM"],
    }),
    getLastNodePPMRead: builder.query<LastNodePPMResponse, LastNodePPMParams>({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `/nodes/${id}/lastNodePPMRead`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetLastNodePPMReadQuery } = nodesPPMApi;
