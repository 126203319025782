import { FC } from "react";
import { displayTextMcfAndKg } from "../helpers";
import { AlarmEvent } from "../../types";
import styles from "../styles.module.scss";
import classNames from "classnames";
import { convertTimezone } from "../../../../helpers/dayjsHelpers";
import { GpaqsRollingAveragesObject } from "../../../../store/gpaqsRollingAverages/api";
import { Grid } from "../../../../pages/SiteMapv2/types";
import LocationEstimates from "./LocationEstimates/LocationEstimates";
import { Spin } from "antd";

interface AlarmAlertTableProps {
  alarmType: string;
  hits: number;
  stdvs: number;
  avg: number;
  mcfToKg: number;
  recipients: string[] | string;
  mostLikelyLeakEvent?: AlarmEvent;
  mostLikelyLeakEventII?: AlarmEvent;
  timestamp: string;
  timezone: string;
  averageGpaqsEmissionRates?: GpaqsRollingAveragesObject;
  endTimeText: string;
  rollingAveragesConfig: number;
  siteGrid: Grid | undefined;
}

export const leakLocationName = (
  grid: Grid,
  cellCoordinates: string
): string => {
  const cellDict = grid[cellCoordinates];
  if (typeof cellDict === "object" && cellDict !== null && "name" in cellDict) {
    return String(cellDict.name);
  } else {
    return `Cell: ${cellCoordinates}`;
  }
};

const AlarmAlertTable: FC<AlarmAlertTableProps> = ({
  alarmType,
  hits,
  stdvs,
  avg,
  mcfToKg,
  recipients,
  mostLikelyLeakEvent,
  mostLikelyLeakEventII,
  timestamp,
  timezone,
  averageGpaqsEmissionRates,
  endTimeText,
  rollingAveragesConfig,
  siteGrid,
}) => {
  return siteGrid === undefined ? (
    <Spin />
  ) : (
    <div className="alarm-alert-table">
      <h1>{alarmType}</h1>
      <p>
        * At least <b>{hits}</b> measurements, <b>{stdvs}</b> standard
        deviation(s) above <b>{displayTextMcfAndKg("mcf", avg, mcfToKg)}</b>{" "}
        site average
      </p>
      <h2>Recipients</h2>
      <p>
        {recipients !== undefined &&
        recipients.length !== 0 &&
        typeof recipients !== "string"
          ? recipients.map((recipient) => recipient)
          : "N/A"}
      </p>
      <h2>Location Estimates</h2>
      {mostLikelyLeakEvent !== undefined ? (
        <table className="alarm-details-container-table ">
          <tr>
            <td>
              <div className={styles.LikelyLeakLocationWrapper}>
                1.
                {leakLocationName(
                  siteGrid,
                  mostLikelyLeakEvent.likelyLeakLocation
                )}
                <div
                  className={classNames(
                    styles.Label,
                    styles.LikelyLeakLocation1
                  )}
                />
              </div>
            </td>
            <td>
              <LocationEstimates
                leakRateMCFH={mostLikelyLeakEvent["avgRate(MCF/h)"]}
                mcfToKg={mcfToKg}
                upperErrorMCFH={mostLikelyLeakEvent?.UpperErrorMcfh}
                lowerErrorMCFH={mostLikelyLeakEvent?.LowerErrorMcfh}
              />
            </td>
          </tr>
          {mostLikelyLeakEventII !== undefined ? (
            <tr>
              <td>
                <div className={styles.LikelyLeakLocationWrapper}>
                  2.
                  {leakLocationName(
                    siteGrid,
                    mostLikelyLeakEventII.likelyLeakLocation
                  )}
                  <div
                    className={classNames(
                      styles.Label,
                      styles.LikelyLeakLocation2
                    )}
                  />
                </div>
              </td>
              <td>
                <LocationEstimates
                  leakRateMCFH={mostLikelyLeakEventII["avgRate(MCF/h)"]}
                  mcfToKg={mcfToKg}
                  upperErrorMCFH={mostLikelyLeakEventII?.UpperErrorMcfh}
                  lowerErrorMCFH={mostLikelyLeakEventII?.LowerErrorMcfh}
                />
              </td>
            </tr>
          ) : null}
        </table>
      ) : null}
      <h2>Leak Statistics</h2>
      <table className="alarm-details-container-table ">
        <tr>
          <td>Start Time</td>
          <td>
            {convertTimezone(timestamp, "UTC", timezone)} {timezone}
          </td>
        </tr>
        <tr>
          <td>End Time</td>
          <td>{endTimeText}</td>
        </tr>
      </table>
      <h2>Site Wide Rolling Averages</h2>
      <table className="alarm-details-container-table ">
        <tr>
          <th>Duration</th>
          <th>Average</th>
          <th>Standard Deviation</th>
        </tr>
        <tr
          className={classNames({ highlighted: rollingAveragesConfig === 1 })}
        >
          <td>7-Day</td>
          <td>
            {averageGpaqsEmissionRates !== undefined
              ? displayTextMcfAndKg(
                "mcf",
                parseFloat(
                  averageGpaqsEmissionRates["7_days_average_emission_rates"]
                ),
                mcfToKg
              )
              : null}
          </td>
          <td>
            {averageGpaqsEmissionRates !== undefined
              ? displayTextMcfAndKg(
                "mcf",
                parseFloat(
                  averageGpaqsEmissionRates[
                    "7_days_average_standard_deviation"
                  ]
                ),
                mcfToKg
              )
              : null}
          </td>
        </tr>
        <tr
          className={classNames({ highlighted: rollingAveragesConfig === 2 })}
        >
          <td>30-Day</td>
          <td>
            {averageGpaqsEmissionRates !== undefined
              ? displayTextMcfAndKg(
                "mcf",
                parseFloat(
                  averageGpaqsEmissionRates["30_days_average_emission_rates"]
                ),
                mcfToKg
              )
              : null}
          </td>
          <td>
            {averageGpaqsEmissionRates !== undefined
              ? displayTextMcfAndKg(
                "mcf",
                parseFloat(
                  averageGpaqsEmissionRates[
                    "30_days_average_standard_deviation"
                  ]
                ),
                mcfToKg
              )
              : null}
          </td>
        </tr>
        <tr
          className={classNames({ highlighted: rollingAveragesConfig === 3 })}
        >
          <td>90-Day</td>
          <td>
            {averageGpaqsEmissionRates !== undefined
              ? displayTextMcfAndKg(
                "mcf",
                parseFloat(
                  averageGpaqsEmissionRates["90_days_average_emission_rates"]
                ),
                mcfToKg
              )
              : null}
          </td>
          <td>
            {averageGpaqsEmissionRates !== undefined
              ? displayTextMcfAndKg(
                "mcf",
                parseFloat(
                  averageGpaqsEmissionRates[
                    "90_days_average_standard_deviation"
                  ]
                ),
                mcfToKg
              )
              : null}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AlarmAlertTable;
