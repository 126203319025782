import { addPushpins, removePushPins } from "./mapApi";

export const topographicOverlay = (overlays = []) => {
  const Microsoft = window.Microsoft;
  const map = window.map;
  overlays.forEach((overlayItem) => {
    const overlay = new Microsoft.Maps.GroundOverlay({
      bounds: Microsoft.Maps.LocationRect.fromEdges(
        overlayItem.imageDetails.topLeft.latitude,
        overlayItem.imageDetails.topLeft.longitude,
        overlayItem.imageDetails.bottomRight.latitude,
        overlayItem.imageDetails.bottomRight.longitude
      ),
      imageUrl: overlayItem.url,
      opacity: overlayItem.imageDetails.opacity,
      rotation: overlayItem.imageDetails.rotation
    });
    overlay.metadata = { id: overlayItem.imageDetails.SiteId };
    overlay.setOptions({ drawOrder: -1 });

    if (overlayItem.imageDetails.SiteId !== undefined) {
      const hasLayer = validateLayer(overlayItem.imageDetails.SiteId);
      if (!hasLayer) {
        map.layers.insert(overlay);
      }
    } else {
      map.layers.insert(overlay);
    }
  });
};

export const movableOverlay = (lat, lon, url, opacity, id, rotation) => {
  const Microsoft = window.Microsoft;
  const map = window.map;

  id !== undefined ? removeOverlayById(id) : removeMovableOverlay();
  removePushPins();

  const centerLat = Number(lat);
  const centerLon = Number(lon);
  const centerLocation = new Microsoft.Maps.Location(centerLat, centerLon);
  const viewportBounds = map.getBounds();
  const viewportWidth = Math.abs(
    viewportBounds.getEast() - viewportBounds.getWest()
  );
  const viewportHeight = Math.abs(
    viewportBounds.getNorth() - viewportBounds.getSouth()
  );

  // Calculate the size of the bounds based on 50% of the minimum viewport dimension
  const minDimension = Math.min(viewportWidth, viewportHeight);
  const boundSize = minDimension * 0.5;

  // Calculate the new bounds
  const bounds = Microsoft.Maps.LocationRect.fromEdges(
    centerLocation.latitude + boundSize / 2,
    centerLocation.longitude - boundSize / 2,
    centerLocation.latitude - boundSize / 2,
    centerLocation.longitude + boundSize / 2
  );
  const overlayImage = new Microsoft.Maps.GroundOverlay({
    centerLocation,
    bounds,
    imageUrl: url,
    opacity,
    rotation
  });

  overlayImage.metadata = { id };

  map.layers.insert(overlayImage);

  const topLeftPin = new Microsoft.Maps.Pushpin(bounds.getNorthwest(), {
    draggable: true,
    icon: topLeftCornerSvg
  });
  const bottomRightPin = new Microsoft.Maps.Pushpin(bounds.getSoutheast(), {
    draggable: true,
    icon: bottomRightCornerSvg
  });
  const centerPin = new Microsoft.Maps.Pushpin(centerLocation, {
    draggable: true,
    icon: movableCenterSvg
  });

  const updateBounds = () => {
    const innerLayer = map.layers.find((l) => l.metadata.id === id);
    const newBounds = Microsoft.Maps.LocationRect.fromShapes([
      topLeftPin,
      bottomRightPin
    ]);
    const center = newBounds.center;
    centerPin.setLocation(center);
    centerLocation.latitude = center.latitude;
    centerLocation.longitude = center.longitude;
    innerLayer.setOptions({ bounds: newBounds });
  };

  const updateCenter = () => {
    const innerLayer = map.layers.find((l) => l.metadata.id === id);
    const center = centerPin.getLocation();
    const centerDiffLat = center.latitude - centerLocation.latitude;
    const centerDiffLon = center.longitude - centerLocation.longitude;

    centerLocation.latitude = center.latitude;
    centerLocation.longitude = center.longitude;

    const currentBounds = Microsoft.Maps.LocationRect.fromShapes([
      topLeftPin,
      bottomRightPin
    ]);
    const newNorth = currentBounds.getNorth() + centerDiffLat;
    const newWest = currentBounds.getWest() + centerDiffLon;
    const newSouth = currentBounds.getSouth() + centerDiffLat;
    const newEast = currentBounds.getEast() + centerDiffLon;
    const newBounds = Microsoft.Maps.LocationRect.fromEdges(
      newNorth,
      newWest,
      newSouth,
      newEast
    );

    topLeftPin.setLocation(newBounds.getNorthwest());
    bottomRightPin.setLocation(newBounds.getSoutheast());

    innerLayer.setOptions({ bounds: newBounds });
  };

  Microsoft.Maps.Events.addHandler(topLeftPin, "drag", updateBounds);
  map.entities.push(topLeftPin);
  Microsoft.Maps.Events.addHandler(bottomRightPin, "drag", updateBounds);
  map.entities.push(bottomRightPin);
  Microsoft.Maps.Events.addHandler(centerPin, "drag", updateCenter);
  map.entities.push(centerPin);
};

export const removeMovableOverlay = () => {
  const Microsoft = window.Microsoft;
  const map = window.map;

  const mapLayers = map.layers;

  for (let i = 0; i < mapLayers.length; i++) {
    const layer = mapLayers[i];

    if (layer instanceof Microsoft.Maps.GroundOverlay) {
      map.layers.remove(layer);
    }
  }
};

export const removeOverlayById = (id) => {
  try {
    const layer = window.map.layers.find((l) => String(l.metadata.id) === String(id));
    if (layer) window.map.layers.remove(layer);
  } catch (e) {
    console.log(e);
  }
};

const topLeftCornerSvg =
  "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path fill=\"none\" stroke=\"white\" stroke-width=\"2\" d=\"M2 22v-20h20\"/></svg>";
const bottomRightCornerSvg =
  "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path fill=\"none\" stroke=\"white\" stroke-width=\"2\" d=\"M2 22h20v-20\"/></svg>";
const movableCenterSvg = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
  <path fill="none" d="M0 0h48v48H0z"/>
  <path d="M36 12L12 36M12 12l24 24" stroke="#FFFFFF" stroke-width="4"/>
</svg>`;

export const initMapLocationSelection = (
  setLatitude,
  setLongitude,
  setLocationHandlerId
) => {
  const Microsoft = window.Microsoft;
  const map = window.map;
  const handlerId = Microsoft.Maps.Events.addHandler(map, "click", (e) => {
    if (e.targetType === "map") {
      const point = new Microsoft.Maps.Point(e.getX(), e.getY());
      const location = e.target.tryPixelToLocation(point);
      setLongitude(location.longitude);
      setLatitude(location.latitude);
      addPushpins([
        {
          center: {
            latitude: location.latitude,
            longitude: location.longitude
          },
          options: {
            title: "center"
          }
        }
      ]);
    }
  });
  setLocationHandlerId(handlerId);
};

export const finishMapLocationSelection = (handlerId) => {
  window.Microsoft.Maps.Events.removeHandler(handlerId);
};

export const getOverlayBounds = (id) => {
  const layer = window.map.layers.find((l) => l.metadata.id === id);
  const bounds = layer.getBounds();
  const topLeft = bounds.getNorthwest();
  const bottomRight = bounds.getSoutheast();
  const opacity = layer.getOpacity();
  const rotation = layer.getRotation();
  return {
    opacity,
    rotation,
    topLeft: {
      latitude: topLeft.latitude,
      longitude: topLeft.longitude
    },
    bottomRight: {
      latitude: bottomRight.latitude,
      longitude: bottomRight.longitude
    }
  };
};

export const removeOverlay = (siteId) => {
  const map = window.map;
  const overlay = map.layers.find(
    (layer) => layer.metadata !== undefined && layer.metadata.id === siteId
  );
  map.layers.remove(overlay);
};

export const drawOverlayOnly = (siteId, rotation, opacity, imageUrl) => {
  const imageDetails = getOverlayBounds(siteId);
  imageDetails.SiteId = siteId;
  if (rotation) {
    imageDetails.rotation = rotation;
  }
  if (opacity) {
    imageDetails.opacity = opacity;
  }
  removeOverlay(siteId);
  topographicOverlay([{ imageDetails, url: imageUrl }]);
};

export const validateLayer = (id) => {
  const layer = window.map.layers.find(
    (l) => l.metadata && l.metadata.id === id
  );
  return !!layer;
};
