// NOMENCLATURE
// "Timestamp" refers to the whole string: 2024-04-09 09:33:56
// "Date" refers to just the day specifier: 2024-04-09

import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss";
const START_OF_DAY_TIMESTAMP_FORMAT = "YYYY-MM-DD 00:00:00";
const DATE_FORMAT = "YYYY-MM-DD";

export const getUtcDayjs = (timestamp: string): Dayjs => {
  return dayjs(timestamp).utc();
};

export const getUnixSeconds = (timestamp: string): number => {
  return dayjs(timestamp).unix();
};

export const getDateString = (timestamp?: string | undefined): string => {
  // default: get today's date
  return dayjs(timestamp).format(DATE_FORMAT);
};

export const getStartOfTodayString = (): string => {
  return dayjs().startOf("day").format(START_OF_DAY_TIMESTAMP_FORMAT);
};

export const getStartOfTomorrowString = (): string => {
  return dayjs()
    .add(1, "day")
    .startOf("day")
    .format(START_OF_DAY_TIMESTAMP_FORMAT);
};

export const getTimestampFromMilliseconds = (
  milliseconds: number,
  timezone = "UTC"
): string => {
  // assumes provided milliseconds are in specified timezone, not unix
  const originalDefaultTz = dayjs.tz.guess();
  dayjs.tz.setDefault(mapToDayjsTimezone(timezone));
  const result = dayjs(new Date(milliseconds)).format(TIMESTAMP_FORMAT);
  dayjs.tz.setDefault(originalDefaultTz);
  return result;
};

export const getHoursSinceStatusCheck = (): string => {
  const currentHour = dayjs().utc().format("HH");
  const currentHourMountain = (Number(currentHour) - 6 + 24) % 24;
  if (currentHourMountain < 12) {
    return String(currentHourMountain);
  }
  return String(Number(currentHourMountain) - 12);
};

const mapToDayjsTimezone = (timezone: string): string => {
  switch (timezone) {
    case "PST":
      return "US/Pacific";
    case "MST":
      return "US/Mountain";
    case "CST":
      return "US/Central";
    case "EST":
      return "US/Eastern";
    default:
      return "UTC";
  }
};

export const isValidTimestamp = (timestamp: string): boolean => {
  return dayjs(timestamp).isValid();
};

export const convertTimezone = (
  timestamp: string,
  sourceTimezone: string,
  targetTimezone: string
): string => {
  const sourceTimezoneDayjs = mapToDayjsTimezone(sourceTimezone);
  const sourceDayjs = dayjs.tz(timestamp, sourceTimezoneDayjs);
  const targetTimezoneDayjs = mapToDayjsTimezone(targetTimezone);
  const targetDayjs = sourceDayjs.clone().tz(targetTimezoneDayjs);

  return targetDayjs.format("YYYY-MM-DD HH:mm:ss");
};

export const adjustTo30sBasis = (timestamp: string): string => {
  const tDayjs = dayjs(timestamp);
  const seconds = tDayjs.second();
  let roundedTDayjs = tDayjs.startOf("minute");

  if (seconds > 15) {
    roundedTDayjs = roundedTDayjs.add(30, "seconds");
  }

  if (seconds > 45) {
    roundedTDayjs = roundedTDayjs.add(30, "seconds");
  }

  return roundedTDayjs.format("YYYY-MM-DD HH:mm:ss");
};
