import { FC } from "react";
import AverageEmissonRatesChart from "./AverageEmissonRatesChart/AverageEmissionRatesChart";
import { singleNodeSitePAQSApi } from "../../store/singleNodeSitePAQS/api";

interface AverageEmissionRatesProps {
  siteId: string;
  siteName: string;
  selectedUnits: string;
  timezone?: string;
  resetChartKey: number;
  chartTimerange: { startTimestamp: string; endTimestamp: string };
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
}
const AverageEmissionRates: FC<AverageEmissionRatesProps> = ({
  siteId,
  siteName,
  selectedUnits,
  timezone,
  resetChartKey,
  chartTimerange,
  handleSetChartTimerange,
}) => {
  const singleNodeSitePAQSPayload = {
    siteId,
    siteName,
    startDate: chartTimerange.startTimestamp,
    endDate: chartTimerange.endTimestamp,
    timezone,
  };
  const singleNodeSitePAQSResponse =
    singleNodeSitePAQSApi.endpoints.getGpaqsAverageEmissionRates.useQuery(
      singleNodeSitePAQSPayload,
      { skip: siteId === "" }
    );
  const data = singleNodeSitePAQSResponse?.data?.result ?? [];
  return (
    <div style={{ width: "99%" }}>
      <AverageEmissonRatesChart
        siteName={siteName}
        data={data}
        units={selectedUnits}
        timezone={timezone}
        resetChartKey={resetChartKey}
        chartTimerange={chartTimerange}
        handleSetChartTimerange={handleSetChartTimerange}
      />
    </div>
  );
};

export default AverageEmissionRates;
