import { Button, Col, Input, Row, Select, Switch, Typography } from "antd";
import React, { FC } from "react";
import styles from "./styles.module.scss";
import AlarmSection from "../Alarms/AlarmSection/AlarmSection";

import { useGetGlobalConfigQuery } from "../../store/globalConfig/api";
import {
  mcfToSiteEmissionUnits,
  mcfToKgEmissionInput,
} from "../../helpers/unitsHelpers";
import { SiteAlarmsConfigProps } from "./types";
const filterKeys = ["SiteId", "Timestamp"];

const { Text } = Typography;

const options = [
  {
    value: "MCF",
    label: "MCF",
  },
  {
    value: "kg",
    label: "kg",
  },
];

const { Option } = Select;

const SiteAlarmsConfig: FC<SiteAlarmsConfigProps> = ({
  units,
  alertParams,
  alarmParams,
  concentrationParams,
  recipients,
  alertRecipients,
  concentrationRecipients,
  onChangeAlarmParams,
  onChangeAlertParams,
  onChangeConcentrationParams,
  onSaveAlarms,
  rollingAverages,
  userEmails,
  onChangeRecipients,
  onChangeAlertRecipients,
  onChangeConcentrationRecipients,
  isBulkAssign,
  setUnits,
  gpaqsDatasetRangeInHours,
  setGpaqsDatasetRangeInHours,
  minimumDetectionThreshold,
  setMinimumDetectionThreshold,
  handleSiteChange,
  siteOptions,
}) => {
  const globalConfig = useGetGlobalConfigQuery(null).data;
  const handleUnitChange = (value: string): void => {
    setUnits(value);
    setMinimumDetectionThreshold(
      mcfToKgEmissionInput(minimumDetectionThreshold, value, globalConfig)
    );
  };
  return (
    <div>
      {isBulkAssign !== null && isBulkAssign !== undefined && isBulkAssign ? (
        <div>
          <Select
            placeholder="Select Sites"
            style={{ width: 200 }}
            mode="multiple"
            onChange={handleSiteChange}
          >
            {siteOptions}
          </Select>
          <Select
            options={options}
            value={units}
            onChange={handleUnitChange}
            style={{ marginLeft: "10px" }}
          />
        </div>
      ) : (
        <Row align="middle">
          <Col span={6}>
            <h3>Alarms</h3>
            <Text type="secondary" style={{ fontSize: 16 }}>
              Configure settings for custom alarms
            </Text>
          </Col>
          <Col span={6}>
            {rollingAverages !== null ? (
              <div
                style={{
                  padding: 10,
                  border: "1px solid silver",
                  marginRight: 15,
                  fontSize: 12,
                }}
              >
                {Object.keys(rollingAverages)
                  .filter((key) => !filterKeys.includes(key))
                  .sort((a, b) => {
                    const extractNumber = (str: string): number => {
                      const parts = str.split("_");
                      return parseInt(parts[0]);
                    };
                    return extractNumber(a) - extractNumber(b);
                  })
                  .map((key: string, index: number) => {
                    return key === "Timestamp" || key === "SiteId" ? null : (
                      <div key={index}>
                        <b>{key.replaceAll("_", " ")}</b>:{" "}
                        {mcfToSiteEmissionUnits(
                          parseFloat(
                            rollingAverages[key as keyof typeof rollingAverages]
                          ),
                          units,
                          globalConfig
                        )}{" "}
                        {units}/hr
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </Col>
          <Col span={6} style={{ textAlign: "center" }}>
            Units
            <Select
              options={options}
              value={units}
              onChange={handleUnitChange}
              style={{ marginLeft: "10px" }}
            />
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              size="large"
              onClick={onSaveAlarms}
              style={{ width: "150px", float: "right" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      )}

      <div className={styles.Alarm} style={{ paddingTop: 5 }}>
        <div>
          <b className={styles.AlarmTitle}>General Config</b>
        </div>
        <AlarmSection
          mainText="Time Range"
          description="How many hours of data will be gathered during the analysis"
          element={
            <Input
              type="number"
              value={gpaqsDatasetRangeInHours}
              step={1}
              style={{ width: 80 }}
              onChange={(e) => {
                setGpaqsDatasetRangeInHours(parseInt(e.target.value));
              }}
            />
          }
        />
        <AlarmSection
          mainText={`Minimum Notification Threshold (${units}/hr)`}
          description="Minimum threshold to receive a notification."
          element={
            <div>
              <Input
                type="number"
                value={minimumDetectionThreshold}
                onChange={(e) => {
                  setMinimumDetectionThreshold(parseFloat(e.target.value));
                }}
                style={{ width: 80 }}
              />
            </div>
          }
        />
      </div>

      <div className={styles.Alarm}>
        <div>
          <b className={styles.AlarmTitle}>High Concentration Alert</b>
        </div>
        <Text type="secondary">
          Notification for when a node records higher than 100 ppm reading. This
          can be indicative of elevated activity on a pad.
        </Text>
        <AlarmSection
          mainText="Enabled:"
          description="When enabled recipients will receive email notifications when there is elevated methane concentration readings on a pad."
          element={
            <Switch
              onChange={(newValue) =>
                onChangeConcentrationParams(newValue, "enabled")
              }
              checked={concentrationParams.enabled}
            />
          }
        />
        <AlarmSection
          mainText="Recipients:"
          description="Recipients will receive email notifications from alerts@earthview.io when a high concentration reading is measured."
          element={
            <Select
              style={{ width: 300 }}
              mode="multiple"
              allowClear
              value={concentrationRecipients}
              onChange={onChangeConcentrationRecipients}
            >
              {userEmails.map((option: string, index: number) => (
                <Option key={index} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          }
        />
      </div>
      <div className={styles.Alarm}>
        <div>
          <b className={styles.AlarmTitle}>Alert Parameters</b>
        </div>
        <Text type="secondary">
          Alerts are lower level notifications to indicate abnormal activity at
          a location. An Alert will be triggered before an Alarm if the
          following conditions are met.
        </Text>
        <AlarmSection
          mainText="Enabled:"
          description="Alerts are triggered when the following conditions are met."
          element={
            <Switch
              onChange={(newValue) => onChangeAlertParams(newValue, "enabled")}
              checked={alertParams.enabled}
            />
          }
        />

        <AlarmSection
          mainText="Standard deviations:"
          description="Determine how many standard deviations above the site wide average to raise an Alert."
          element={
            <Select
              value={alertParams.sumOfDeviations}
              onChange={(newValue) =>
                onChangeAlertParams(newValue, "sumOfDeviations")
              }
            >
              <Option value={1}>1</Option>
              <Option value={3}>3</Option>
              <Option value={5}>5</Option>
            </Select>
          }
        />
        <AlarmSection
          mainText="7, 30, 90 rolling averages:"
          description="Determine range of background emissions to take as parameter for the calculations."
          element={
            <Select
              value={alertParams.rollingAverageConfig}
              onChange={(newValue) =>
                onChangeAlertParams(newValue, "rollingAverageConfig")
              }
            >
              <Option value={1}>7 days</Option>
              <Option value={2}>30 days</Option>
              <Option value={3}>90 days</Option>
            </Select>
          }
        />
        <AlarmSection
          mainText="Hits above the background threshold:"
          description="Determine how many measurements will define an event."
          element={
            <Select
              value={alertParams.hits}
              onChange={(newValue) => onChangeAlertParams(newValue, "hits")}
            >
              <Option value={1}>1</Option>
              <Option value={3}>3</Option>
              <Option value={6}>6</Option>
            </Select>
          }
        />
        <AlarmSection
          mainText="Recipients:"
          description="Recipients will receive email notifications from alerts@earthview.io when an Alert is triggered."
          element={
            <Select
              style={{ width: 300 }}
              mode="multiple"
              allowClear
              value={alertRecipients}
              onChange={onChangeAlertRecipients}
            >
              {userEmails.map((option: string, index: number) => (
                <Option key={index} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          }
        />
      </div>
      <div className={styles.Alarm}>
        <div>
          <b className={styles.AlarmTitle}>Alarm Parameters</b>
        </div>
        <Text type="secondary">
          Alarms are higher level notifications. An Alert first gets created
          that indicates abnormal activity on a location. If the event continues
          in both duration and magnitude it is elevated to an Alarm.
        </Text>
        <AlarmSection
          mainText="Enabled:"
          description="Alarms are triggered when the following conditions are met."
          element={
            <Switch
              onChange={(newValue) => onChangeAlarmParams(newValue, "enabled")}
              checked={alarmParams.enabled}
            />
          }
        />
        <AlarmSection
          mainText="Standard deviations:"
          description="Determine how many standard deviations above the site wide average to raise an Alarm."
          element={
            <Select
              value={alarmParams.sumOfDeviations}
              onChange={(newValue) =>
                onChangeAlarmParams(newValue, "sumOfDeviations")
              }
            >
              <Option value={1}>1</Option>
              <Option value={3}>3</Option>
              <Option value={5}>5</Option>
            </Select>
          }
        />
        <AlarmSection
          mainText="7, 30, 90 rolling averages:"
          description="Determine range of background emissions to take as parameter for the calculations."
          element={
            <Select
              value={alarmParams.rollingAverageConfig}
              onChange={(newValue) =>
                onChangeAlarmParams(newValue, "rollingAverageConfig")
              }
            >
              <Option value={1}>7 days</Option>
              <Option value={2}>30 days</Option>
              <Option value={3}>90 days</Option>
            </Select>
          }
        />
        <AlarmSection
          mainText="Hits above the background threshold:"
          description="Determine how many measurements will define an event."
          element={
            <Select
              value={alarmParams.hits}
              onChange={(newValue) => onChangeAlarmParams(newValue, "hits")}
            >
              <Option value={1}>1</Option>
              <Option value={3}>3</Option>
              <Option value={6}>6</Option>
            </Select>
          }
        />
        <AlarmSection
          mainText="Recipients:"
          description="Recipients will receive email notifications from alerts@earthview.io when an Alarm is triggered."
          element={
            <Select
              style={{ width: 300 }}
              mode="multiple"
              allowClear
              value={recipients}
              onChange={onChangeRecipients}
            >
              {userEmails.map((option: string, index: number) => (
                <Option key={index} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          }
        />
      </div>
    </div>
  );
};

export default SiteAlarmsConfig;
